import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ResponseNet } from 'src/app/model/ResponseNet.model';
import { ResponseUser } from 'src/app/model/User.model';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email: string;
  senha: string;

  constructor(
    private router: Router,
    private ajax: AjaxService,
    private util: UtilService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.email = '';
    this.senha = '';
  }

  login(){
    //let url = "https://api.qrcode.lumago.com.br/api/";
    //console.log(this.email, this.senha);
    this.util.showLoading();
    var url = API + 'Auth/User?email='+this.email+'&senha='+this.senha;
    this.ajax.get(url)
      .subscribe((user: ResponseUser) => {

        this.util.closeLoading();
        debugger
        this.auth.setUser(user);
        this.util.toasterShowSuccess("Sucesso", "Bem Vindo " + user.user.nome);
        this.router.navigate(['/home']);
        //console.log(apt);

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Usuário ou Senha inválidos");
      });

  }

}
