// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import createNumberMask from "text-mask-addons/dist/createNumberMask";


export const environment = {
  production: false
};

export const SecretToken = 'aedaf9d8863b48e197b9287c492a708e';

export const REDIRECT_SITE = "https://www.qrpig.com";
export const API_QRCODE = "https://app.qrpig.com/g/";
export const API = "https://api.qrpig.com/api/";
//export const API = "https://localhost:5001/api/";
export const SUBTIPOS = [
  {nome: "comum", id:"1E5A0F06-1F5B-4FB8-A11D-9DF8B8FC515A"},
  {nome: "linkedin", id: "7E5A0F06-1F5B-4FB8-A11D-9DF8B8FC515A"},
  {nome: "whatsapp", id: "2E5A0F06-1F5B-4FB8-A11D-9DF8B8FC515B"},
  {nome: "instagram", id: "8E5A0F06-1F5B-4FB8-A11D-9DF8B8FC515B"},
  {nome: "telegram", id: "3E5A0F06-1F5B-4FB8-A11D-9DF8B8FC515C"},
  {nome: "facebook", id: "4E5A0F06-1F5B-4FB8-A11D-9DF8B8FC515D"},
  {nome: "twitter", id: "5E5A0F06-1F5B-4FB8-A11D-9DF8B8FC515E"},
  {nome: "youtube", id: "6E5A0F06-1F5B-4FB8-A11D-9DF8B8FC515F"},
  {nome: "texto", id: "9E5A0F06-1F5B-4FB8-A11D-9DF8B8FC5133"},
  {nome: "conteudo", id: "105A0F06-1F5B-4FB8-A11D-8DF8B8FC1313"},
  {nome: "telefone", id: "115A0F06-1F5B-4FB8-A11D-8DF8B8FC131D"},
  {nome: "pdf", id: "125A0F06-1F5B-4FB7-A11D-8DF7B8FC221A"},
  {nome: "arquivo", id: "145A0F06-1F5B-4FB7-A11D-8DF7B8FC441B"},
  {nome: "mp3", id: "135A0F06-1F5B-4FB7-A11D-8DF7B8FC331A"},
  {nome: "mp3Fundo", id: "135a0f06-1f5b-4fb7-a11d-8df7b8fc337a"},
  {nome: "mapa", id: "185A0F07-2F6B-4FB8-A11D-8DF7B8FC551B"},
];

export const TIPOS =  {EntradaDeDados:"39943721-e576-491a-80ab-59bce4e3c435"};

export const maskTelefoneCelular = ['(', /\d/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
export const maskTeleFixo = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
export const maskCPF = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/ ,'-', /\d/, /\d/];
export const numberMaskInt = createNumberMask({
  prefix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  decimalSymbol: ',',
  allowDecimal: false,
  decimalLimit: 0,
  requireDecimal: false,
  allowNegative: false,
  allowLeadingZeroes: false,
  //integerLimit: 8
})
export const numberMaskReal = createNumberMask({
  prefix: 'R$ ',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  decimalSymbol: ',',
  allowDecimal: true,
  decimalLimit: 2,
  requireDecimal: false,
  allowNegative: false,
  allowLeadingZeroes: false,
  //integerLimit: 8
})

export const SUBTIPOS_CARTAO_VISITA = [
  //{nome: "Texto Livre", id:"1596e7dc-514e-4c31-b713-8bb4e4442ea9"},
  {nome: "Facebook", id: "1596e7dc-514e-4c31-b713-8bb4e4442ea8"},
  {nome: "Instagram", id: "1596e7dc-514e-4c31-b713-8bb4e4442ea7"},
  {nome: "Linkedin", id: "1596e7dc-514e-4c31-b713-8bb4e4442ea6"},
  //{nome: "E-mail", id: "1596e7dc-514e-4c31-b713-8bb4e4442ea5"},
  {nome: "YouTube", id: "1596e7dc-514e-4c31-b713-8bb4e4442ea4"},
  {nome: "Twitter", id: "1596e7dc-514e-4c31-b713-8bb4e4442ea3"},
  {nome: "Telefone", id: "1596e7dc-514e-4c31-b713-8bb4e4442ea2"},
  {nome: "Foto", id: "1596e7dc-514e-4c31-b713-8bb4e4442ea1"},
  //{nome: "Fundo", id: "1596e7dc-514e-4c31-b713-8bb4e4442ea0"},
  {nome: "Site", id: "1096e7dc-534e-4c31-b713-8bb4e4442ea5"},
  {nome: "Whatsapp", id: "1196e7dc-524e-4c31-b713-8bb4e4442ea0"},
  {nome: "Telegram", id: "1286E7DC-524E-4C31-B713-8BB4E4442EA3"},
  {nome: "Celular", id: "1896e7dc-514e-5c31-b713-7bb4e4442ea4"},
  {nome: "Mapa", id: "2896e8dc-515e-5c32-b714-7bb4e4542ea4"},
  {nome: "Música Fundo", id: "135a0f06-1f5b-4fb7-a11d-8df7b8fc337a"}


];

export const recaptcha = {
  siteKey: '6LfToUIdAAAAAH9g5A4jH6NL7NnTi1d8BWhDVpS3',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
