<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>

<div class="container">

  <div class="row formQrCode" *ngIf="objQrcode != undefined && objQrcode.tipo == 'Entrada de Dados'">

    <div class="auth-wrapper align-items-stretch aut-bg-img {{addClass}}">
      <div class="flex-grow-1">
        <div class="h-100 d-md-flex align-items-center auth-side-img ">
          <div class="col-sm-10 auth-content w-auto">
            <img src="{{imgFundoFormularioPadrao}}" alt="" class="img-fluid" width="40%">
            <h1 class="text-white my-4">Bem Vindo!</h1>
            <h4 class="text-white font-weight-normal">Crie sua conta na plataforma e tenha o mundo do QRCODE a sua disposição.<br /></h4>
          </div>
        </div>
        <div class="auth-side-form">
          <div class=" auth-content">
            <img src="{{imgLogoPadrao}}" alt="" class="img-fluid mb-4 d-block d-xl-none d-lg-none">
            <h3 class="mb-4 f-w-400">{{objQrcode.nome}}</h3>
            <form [formGroup]="myFormGroup" id='frmEntradaDados'>
              <div *ngFor="let form_elem of qrCodeEntradaFormTemplate">
                <div [ngSwitch]="form_elem.type">
                  <div *ngSwitchCase="'text'" clas="row formField">
                    <label style="cursor: pointer;" for="{{form_elem.id}}">{{form_elem.label}}</label>
                    <br />
                    <input type="text" formControlName="{{form_elem.label}}" id="{{form_elem.id}}"
                      class="{{form_elem.class}}" [textMask]="{mask: form_elem.textMask.mask}" />
                  </div>
                  <div *ngSwitchCase="'select'" clas="row formField">
                    <label style="cursor: pointer;" for="{{form_elem.id}}">{{form_elem.label}}</label>
                    <br />
                    <select formControlName="{{form_elem.label}}" id="{{form_elem.id}}" class="{{form_elem.class}}">
                      <option *ngFor="let val of form_elem.data" [value]="val.value">
                        {{val.value}}
                      </option>
                    </select>
                  </div>
                  <div *ngSwitchCase="'check'" clas="row formField">
                    <label style="cursor: pointer;" for="{{form_elem.id}}">{{form_elem.label}}</label>
                    <br />
                    <input type="checkbox" (click)="controlarClickCheckboxDinamico($event)" style="cursor: pointer;"
                      formControlName="{{form_elem.label}}" id="{{form_elem.id}}" class="{{form_elem.class}}" />
                    &nbsp;<span style="padding-top:10px !important;" id="{{form_elem.id}}_span"
                      class="{{form_elem.class}}">Não</span>
                  </div>
                  <div *ngSwitchCase="'foto'" clas="row formField">
                  <!--todo>-->
                    <label class="{{form_elem.class}}" style="cursor: pointer;" for="{{form_elem.id}}">{{form_elem.label}}</label>
                    <br />
                    <input  type="file" accept="image/*" (change)="fileBrowseHandler($event.target.files[0],form_elem.label)"  formControlName="{{form_elem.label}}" id="{{form_elem.id}}"
                      class="{{form_elem.class}}"  />
                  </div>

                </div>
              </div>
              <br />
              <span class="info highlighted">{{mensagemCamposObrigatorios}}</span>
              <br />
              <div *ngIf="isExibirBotaoSalvar">
                <input type="button" class="btn btn-primary botao " (click)="salvarEntradaDados()" value="Salvar"
                  *ngIf="isExibirBotaoSalvar" />
              </div>
              <br />
              <br />
              <span class="info">{{objQrcode.conteudo}}</span>
            </form>

          </div>
        </div>
      </div>
    </div>
    <!--<legend>Entrada de Dados</legend>
    <form [formGroup]="myFormGroup" id='frmEntradaDados'>
      <div *ngFor="let form_elem of qrCodeEntradaFormTemplate">
      <div [ngSwitch]="form_elem.type">
        <div *ngSwitchCase="'text'" clas="row">
          <label style="cursor: pointer;"  for="{{form_elem.id}}">{{form_elem.label}}</label>
          <br />
          <input type="text" formControlName="{{form_elem.label}}" id="{{form_elem.id}}" class="{{form_elem.class}}"/>
        </div>
        <div *ngSwitchCase="'select'" clas="row">
          <label style="cursor: pointer;"  for="{{form_elem.id}}">{{form_elem.label}}</label>
          <br />
          <select formControlName="{{form_elem.label}}" id="{{form_elem.id}}" class="{{form_elem.class}}">
          <option *ngFor="let val of form_elem.data"
            [value]="val.value">
           {{val.value}}
         </option>
        </select>
        </div>
        <div *ngSwitchCase="'check'" clas="row">
          <label style="cursor: pointer;"  for="{{form_elem.id}}">{{form_elem.label}}</label>
          <br />
          <input type="checkbox" (click)="controlarClickCheckboxDinamico($event)" style="cursor: pointer;"  formControlName="{{form_elem.label}}" id="{{form_elem.id}}" class="{{form_elem.class}}"/>
          &nbsp;<span style="padding-top:10px !important;" id="{{form_elem.id}}_span" class="{{form_elem.class}}">Não</span>
        </div>
      </div>
      </div>
      <br />
      <span class="info">{{mensagemCamposObrigatorios}}</span>
      <br />
      <div *ngIf="isExibirBotaoSalvar">
      <input type="button" class="btn btn-primary " (click)="salvarEntradaDados()" value="Salvar" *ngIf="isExibirBotaoSalvar" />
      </div>
     </form>-->

  </div>

  <div class="row" *ngIf="objQrcode != undefined && objQrcode.tipo == 'Texto Livre'">
    <div class="col-sm-12 col-md-12 col-xl-12">
      <hr>
      <app-card cardClass="trnasiction-card" [customHeader]="true" headerClass="bg-c-green" blockClass="text-center"
        [isCardFooter]="true" footerClass="border-top transection-footer p-0">
        <div class="app-card-header">
          <h5 class="text-white"><span class="d-block"></span></h5>
          <div class="card-header-right">
            <div class="transection-preogress complited">
              <span class="fa fa-check f-20"></span>
            </div>
          </div>
        </div>
        <div [innerHtml]="htmlConteudo"></div>
      </app-card>
    </div>
  </div>

  <div class="row" *ngIf="mensagemErro != ''">
    <div class="col-sm-12 col-md-12 col-xl-12">
      <hr>
      <app-card cardClass="trnasiction-card" [customHeader]="true" headerClass="bg-c-purple" blockClass="text-center"
        [isCardFooter]="true" footerClass="border-top transection-footer p-0">
        <div class="app-card-header">

          <h5 class="text-white"><span class="d-block">Atenção</span></h5>
          <div class="card-header-right">
            <div class="transection-preogress complited">
              <span class="fa fa-check f-20"></span>
            </div>
          </div>
        </div>
        <div align="center">
          <img src="assets/images/logo/new/qrpig-1.png" alt="" class="img-fluid mb-4 d-block">
          <p>{{mensagemErro}}</p>
        </div>
        <div class="card-footer">
          <a href="{{redirect_site}}">Conheça nossa plataforma e gere seu QRCODE </a>
        </div>

      </app-card>
    </div>
  </div>


  <div class="row mb-n4">
    <!--<div class="col-xl-4 col-md-6">
        <h6 class="text-center mb-3">Simple</h6>
        <app-card [customHeader]="true" headerClass="border-0 p-2 pb-0" [options]="false" cardClass="user-card user-card-1" blockClass="pt-0 users-contact">
          <div class="app-card-header">
            <div class="cover-img-block">
              <img src="assets/images/widget/slider5.jpg" alt="" class="img-fluid">
            </div>
          </div>
          <div class="user-about-block text-center">
            <div class="row align-items-end">
              <div class="col"></div>
              <div class="col">
                <div class="position-relative d-inline-block">
                  <img class="img-radius img-fluid wid-80" src="assets/images/user/avatar-2.jpg" alt="User image">
                </div>
              </div>
              <div class="col"></div>
            </div>
          </div>
          <div class="text-center">
            <h6 class="mb-1 mt-3">Josephin Doe</h6>
            <p class="mb-3 text-muted">UI/UX Designer</p>
            <p class="mb-1">Lorem Ipsum is simply dummy text</p>
            <p class="mb-0">been the industry's standard</p>
          </div>
          <hr class="wid-80 b-wid-3 my-4">
          <div class="row text-center">
            <div class="col">
              <h6 class="mb-1">37</h6>
              <p class="mb-0">Mails</p>
            </div>
            <div class="col">
              <h6 class="mb-1">2749</h6>
              <p class="mb-0">Followers</p>
            </div>
            <div class="col">
              <h6 class="mb-1">678</h6>
              <p class="mb-0">Following</p>
            </div>
          </div>
        </app-card>
      </div>-->
    <div class="col-xl-8 col-md-6" *ngIf="objQrcode != undefined && objQrcode.tipo == 'Cartão de Visita'">

      <!--<meta property="og:site_name" content="QRpig">
      <meta property="og:title" content="QRPig venha criar seu QrCode" />
      <meta property="og:description" content="Gerador de QRCODE dinamicos, cartao de visitas" />
      <meta property="og:type" content="website" />
      <meta property="og:updated_time" content="1440432930" />-->

      <app-card [customHeader]="true" headerClass="border-0 p-2 pb-0" [options]="false"
        cardClass="user-card user-card-2 support-bar1 shape-right" blockClass="pt-0 users-contact"
        [isCardFooter]="false">
        <audio controls *ngIf="musicaFundo?.valor" autoplay > <!--agora com autoplay e sem controls-->
          <source [src]="musicaFundo.valor" type="audio/mp3">
          Your browser does not support the audio tag.
        </audio>
        <div class="app-card-header">
          <div class="cover-img-block">
            <img [src]="fundo.valor" alt="" class="img-fluid">
          </div>
        </div>
        <div class="user-about-block text-center">
          <div class="row align-items-center">
            <div class="col">
              <div class="row align-items-center">
                <div class="col-auto col pr-0">
                  <img class="img-radius img-fluid wid-80" [src]="avatar.valor" alt="User image">
                </div>
                <div class="col">
                  <h6 class="mb-1">{{objQrcode.nome}}</h6>
                  <!--<p class="mb-0">UI/UX Designer</p>-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <!--<p class="mb-1">{{objQrcode.conteudo}}</p>-->
          <div [innerHtml]="objQrcode.conteudo"></div>
          <!--<p class="mb-0">been the industry's standard</p>-->

        </div>

        <!--<ul class="list-inline f-20 mt-3 mb-0">
              <li class="list-inline-item"><a href="javascript:" class="text-facebook"><i class="fab fa-facebook-f"></i></a></li>
              <li class="list-inline-item"><a href="javascript:" class="text-twitter"><i class="fab fa-twitter"></i></a></li>
              <li class="list-inline-item"><a href="javascript:" class="text-dribbble"><i class="fab fa-dribbble"></i></a></li>
              <li class="list-inline-item"><a href="javascript:" class="text-pinterest"><i class="fab fa-pinterest"></i></a></li>
              <li class="list-inline-item"><a href="javascript:" class="text-youtube"><i class="fab fa-youtube"></i></a></li>
              <li class="list-inline-item"><a href="javascript:" class="text-googleplus"><i class="fab fa-google-plus-g"></i></a></li>
              <li class="list-inline-item"><a href="javascript:" class="text-linkedin"><i class="fab fa-linkedin-in"></i></a></li>
            </ul>-->
        <br>
        <!--<div class="row justify-content-center user-social-link">
              <div class="col-auto"><a href="#!"><i class="fab fa-facebook text-facebook"></i></a></div>
              <div class="col-auto"><a href="#!"><i class="fab fa-twitter text-twitter"></i></a></div>
              <div class="col-auto"><a href="#!"><i class="fab fa-dribbble text-dribbble"></i></a></div>
            </div>-->
        <div class="app-card-footer">
          <div class="row text-center">
            <div class="col">

              <i class="fab fa-facebook"></i>


            </div>

            <div class="col">
              <h6 class="mb-1">678</h6>
              <p class="mb-0">Following</p>
            </div>
          </div>
        </div>
      </app-card>

      <div class="col-md-12 col-xl-12" *ngFor="let item of objQrcode.cartaoVisita">
        <app-card [hidHeader]="true" cardClass="bg-dark notification-card">
          <a href="{{item.valor}}" target="_blank" class="text-white">
            <div class="row align-items-center">
              <div class="col-4 notify-icon">

                <i class="fa fa-mobile" *ngIf="item.tipoQrcodeCartaoVisita == 'Celular'"></i>
                <i class="feather icon-phone" *ngIf="item.tipoQrcodeCartaoVisita == 'Telefone'"></i>
                <i class="fab fa-whatsapp" *ngIf="item.tipoQrcodeCartaoVisita == 'Whatsapp'"></i>
                <i class="fas fa-globe" *ngIf="item.tipoQrcodeCartaoVisita == 'Site'"></i>
                <i class="fab fa-facebook" *ngIf="item.tipoQrcodeCartaoVisita == 'Facebook'"></i>
                <i class="fab fa-twitter" *ngIf="item.tipoQrcodeCartaoVisita == 'Twitter'"></i>
                <i class="fab fa-youtube" *ngIf="item.tipoQrcodeCartaoVisita == 'YouTube'"></i>
                <i class="fab fa-linkedin-in" *ngIf="item.tipoQrcodeCartaoVisita == 'Linkedin'"></i>
                <i class="fab fa-instagram" *ngIf="item.tipoQrcodeCartaoVisita == 'Instagram'"></i>
                <i class="fab fa-telegram" *ngIf="item.tipoQrcodeCartaoVisita == 'Telegram'"></i>
                <i class="feather icon-map" *ngIf="item.tipoQrcodeCartaoVisita == 'Mapa'"></i>
                <i class="far fa-file" *ngIf="item.tipoQrcodeCartaoVisita == 'Foto'"></i>

              </div>
              <div class="col-8 notify-cont">
                <!--<p *ngIf="item.tipoQrcodeCartaoVisita != 'Foto'">{{item.tipoQrcodeCartaoVisita}}</p>-->
                <!--<p *ngIf="item.tipoQrcodeCartaoVisita == 'Foto'">{{item.chave}}</p>-->
                <p>{{item.chave}}</p>
              </div>
            </div>
          </a>

        </app-card>
      </div>

    </div>


    <!--<div class="col-md-6 col-lg-4">
          <app-card [hidHeader]="true" blockClass="text-center">
            <i class="fab fa-twitter text-c-green d-block f-40"></i>
            <h4 class="m-t-20"><span class="text-c-blgreenue">+40</span> Followers</h4>
          </app-card>
        </div>-->

  </div>
  <!--<div class="responsive-object" *ngIf="this.linkArquivo != null">
    <embed [src]="transform(this.linkArquivo)" style="width:100%;height:100vh;">
  </div>-->

</div>
