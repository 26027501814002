<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>
<div class="row">
  <h5>Vamos Cadastrar um QrCode</h5>
  <hr>
  <div class="col-12">
    <app-card [options]="false">
      <aw-wizard #wizard class="arc-wizard" navBarLayout="large-filled-symbols">
        <aw-wizard-step stepTitle="Tipo QrCode" [navigationSymbol]="{ symbol: '&#xe8e9;', fontFamily: 'feather' }"
          [style.font-weight]="900">
          <ng-template awWizardStepSymbol>
            <i class="fa fa-qrcode "></i>
          </ng-template>
          <div class="row">

            <div class="col-xl-3 col-md-5">
              <app-card [hidHeader]="true" (click)="escolhaRadio('link')">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="fas fa-globe text-info mr-2"></i> Link </h2>
                  </div>
                  <div class="col text-right">
                    <div class="form-group mb-1">
                      <div class="radio radio-warning radio-fill d-inline">
                        <input type="radio" [(ngModel)]="tipoQrcode" name="tipoQrcode" id="link" value="link">
                        <label for="link" class="cr"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </app-card>
            </div>

            <div class="col-xl-3 col-md-5">
              <app-card [hidHeader]="true" (click)="escolhaRadio('mapa')">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="feather icon-map text-info mr-2"></i> Mapa </h2>
                  </div>
                  <div class="col text-right">
                    <div class="form-group mb-1">
                      <div class="radio radio-warning radio-fill d-inline">
                        <input type="radio" [(ngModel)]="tipoQrcode" name="tipoQrcode" id="mapa" value="mapa">
                        <label for="mapa" class="cr"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </app-card>
            </div>

            <div class="col-xl-3 col-md-5">
              <app-card [hidHeader]="true" (click)="escolhaRadio('texto')">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="fas fa-pencil-alt text-info mr-2"></i> Texto </h2>
                  </div>
                  <div class="col text-right">
                    <div class="form-group mb-1">
                      <div class="radio radio-warning radio-fill d-inline">
                        <input type="radio" [(ngModel)]="tipoQrcode" name="tipoQrcode" id="texto" value="texto">
                        <label for="texto" class="cr"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </app-card>
            </div>

            <div class="col-xl-3 col-md-5">
              <app-card [hidHeader]="true" (click)="escolhaRadio('mp3')">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="fa fa-music text-info mr-2"></i> MP3 </h2>
                  </div>
                  <div class="col text-right">
                    <div class="form-group mb-1">
                      <div class="radio radio-warning radio-fill d-inline">
                        <input type="radio" [(ngModel)]="tipoQrcode" name="tipoQrcode" id="mp3" value="mp3">
                        <label for="mp3" class="cr"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </app-card>
            </div>

            <div class="col-xl-3 col-md-5">
              <app-card [hidHeader]="true" (click)="escolhaRadio('pdf')">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="far fa-file-pdf text-info mr-2"></i> PDF </h2>
                  </div>
                  <div class="col text-right">
                    <div class="form-group mb-1">
                      <div class="radio radio-warning radio-fill d-inline">
                        <input type="radio" [(ngModel)]="tipoQrcode" name="tipoQrcode" id="pdf" value="pdf">
                        <label for="pdf" class="cr"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </app-card>
            </div>

            <div class="col-xl-3 col-md-5">
              <app-card [hidHeader]="true" (click)="escolhaRadio('arquivo')">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="fa fa-archive text-info mr-2"></i> Arquivo </h2>
                  </div>
                  <div class="col text-right">
                    <div class="form-group mb-1">
                      <div class="radio radio-warning radio-fill d-inline">
                        <input type="radio" [(ngModel)]="tipoQrcode" name="tipoQrcode" id="arquivo" value="arquivo">
                        <label for="arquivo" class="cr"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </app-card>
            </div>





            <div class="col-xl-3 col-md-5">
              <app-card [hidHeader]="true" (click)="escolhaRadio('conteudo')">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="far fa-file-alt text-info mr-2"></i> Conteudo </h2>
                  </div>
                  <div class="col text-right">
                    <div class="form-group mb-1">
                      <div class="radio radio-warning radio-fill d-inline">
                        <input type="radio" [(ngModel)]="tipoQrcode" name="tipoQrcode" id="contedo" value="conteudo">
                        <label for="conteudo" class="cr"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </app-card>
            </div>

            <!--<div class="col-xl-3 col-md-5">
              <app-card [hidHeader]="true" (click)="escolhaRadio('podcast')">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="fas fa-podcast text-info mr-2"></i> PodCast </h2>
                  </div>
                  <div class="col text-right">
                    <div class="form-group mb-1">
                      <div class="radio radio-warning radio-fill d-inline">
                        <input type="radio" [(ngModel)]="tipoQrcode" name="tipoQrcode" id="podcast" value="podcast">
                        <label for="podcast" class="cr"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </app-card>
            </div>-->

            <!--<div class="col-xl-3 col-md-5">
              <app-card [hidHeader]="true" (click)="escolhaRadio('email')">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="far fa-envelope text-info mr-2"></i> Email </h2>
                  </div>
                  <div class="col text-right">
                    <div class="form-group mb-1">
                      <div class="radio radio-warning radio-fill d-inline">
                        <input type="radio" [(ngModel)]="tipoQrcode" name="tipoQrcode" id="email" value="email">
                        <label for="email" class="cr"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </app-card>
            </div>-->

            <div class="col-xl-3 col-md-5">
              <app-card [hidHeader]="true" (click)="escolhaRadio('telefone')">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="feather icon-phone text-info mr-2"></i> Telefone
                    </h2>
                  </div>
                  <div class="col text-right">
                    <div class="form-group mb-1">
                      <div class="radio radio-warning radio-fill d-inline">
                        <input type="radio" [(ngModel)]="tipoQrcode" name="tipoQrcode" id="telefone" value="telefone">
                        <label for="telefone" class="cr"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </app-card>
            </div>

            <div class="col-xl-3 col-md-5">
              <app-card [hidHeader]="true" (click)="escolhaRadio('whatsapp')">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="fab fa-whatsapp text-info mr-2"></i> Whatsapp </h2>
                  </div>
                  <div class="col text-right">
                    <div class="form-group mb-1">
                      <div class="radio radio-warning radio-fill d-inline">
                        <input type="radio" [(ngModel)]="tipoQrcode" name="tipoQrcode" id="whatsapp" value="whatsapp">
                        <label for="whatsapp" class="cr"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </app-card>
            </div>

            <div class="col-xl-3 col-md-5">
              <app-card [hidHeader]="true" (click)="escolhaRadio('telegram')">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="fab fa-telegram text-info mr-2"></i> Telegram </h2>
                  </div>
                  <div class="col text-right">
                    <div class="form-group mb-1">
                      <div class="radio radio-warning radio-fill d-inline">
                        <input type="radio" [(ngModel)]="tipoQrcode" name="tipoQrcode" id="telegram" value="telegram">
                        <label for="telegram" class="cr"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </app-card>
            </div>

            <!--<div class="col-xl-3 col-md-5">
              <app-card [hidHeader]="true" (click)="notImplement('skype')">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="fab fa-skype text-info mr-2"></i> Skype </h2>
                  </div>
                  <div class="col text-right">
                    <div class="form-group mb-1">
                      <div class="radio radio-warning radio-fill d-inline">
                        <input type="radio" [(ngModel)]="tipoQrcode" name="tipoQrcode" id="skype" value="skype">
                        <label for="skype" class="cr"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </app-card>
            </div>-->

            <div class="col-xl-3 col-md-5">
              <app-card [hidHeader]="true" (click)="escolhaRadio('facebook')">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="fab fa-facebook-f text-info mr-2"></i> Facebook
                    </h2>
                  </div>
                  <div class="col text-right">
                    <div class="form-group mb-1">
                      <div class="radio radio-warning radio-fill d-inline">
                        <input type="radio" [(ngModel)]="tipoQrcode" name="tipoQrcode" id="facebook" value="facebook">
                        <label for="facebook" class="cr"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </app-card>
            </div>

            <div class="col-xl-3 col-md-5">
              <app-card [hidHeader]="true" (click)="escolhaRadio('twitter')">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="fab fa-twitter text-info mr-2"></i> Twitter </h2>
                  </div>
                  <div class="col text-right">
                    <div class="form-group mb-1">
                      <div class="radio radio-warning radio-fill d-inline">
                        <input type="radio" [(ngModel)]="tipoQrcode" name="tipoQrcode" id="twitter" value="twitter">
                        <label for="twitter" class="cr"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </app-card>
            </div>

            <div class="col-xl-3 col-md-5">
              <app-card [hidHeader]="true" (click)="escolhaRadio('youtube')">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="fab fa-youtube text-info mr-2"></i> Youtube </h2>
                  </div>
                  <div class="col text-right">
                    <div class="form-group mb-1">
                      <div class="radio radio-warning radio-fill d-inline">
                        <input type="radio" [(ngModel)]="tipoQrcode" name="tipoQrcode" id="youtube" value="youtube">
                        <label for="youtube" class="cr"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </app-card>
            </div>

            <div class="col-xl-3 col-md-5">
              <app-card [hidHeader]="true" (click)="escolhaRadio('linkedin')">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="fab fa-linkedin-in text-info mr-2"></i> Linkedin
                    </h2>
                  </div>
                  <div class="col text-right">
                    <div class="form-group mb-1">
                      <div class="radio radio-warning radio-fill d-inline">
                        <input type="radio" [(ngModel)]="tipoQrcode" name="tipoQrcode" id="linkedin" value="linkedin">
                        <label for="linkedin" class="cr"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </app-card>
            </div>

            <!--<div class="col-xl-3 col-md-5">
              <app-card [hidHeader]="true" (click)="escolhaRadio('google')">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="fab fa-google-plus-g text-info mr-2"></i> Google +
                    </h2>
                  </div>
                  <div class="col text-right">
                    <div class="form-group mb-1">
                      <div class="radio radio-warning radio-fill d-inline">
                        <input type="radio" [(ngModel)]="tipoQrcode" name="tipoQrcode" id="google" value="google">
                        <label for="google" class="cr"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </app-card>
            </div>-->

            <div class="col-xl-3 col-md-5">
              <app-card [hidHeader]="true" (click)="escolhaRadio('instagram')">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="fab fa-instagram text-info mr-2"></i> Instagram
                    </h2>
                  </div>
                  <div class="col text-right">
                    <div class="form-group mb-1">
                      <div class="radio radio-warning radio-fill d-inline">
                        <input type="radio" [(ngModel)]="tipoQrcode" name="tipoQrcode" id="instagram" value="instagram">
                        <label for="instagram" class="cr"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </app-card>
            </div>



            <div class="col-xl-4 col-md-6" (click)="cartaoFormulario()">
              <app-card [hidHeader]="true">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="far fa-file-word text-info mr-2"></i> Lead / Formulário
                    </h2>
                  </div>
                </div>
              </app-card>
            </div>

            <div class="col-xl-4 col-md-6" (click)="cartaoVisita()">
              <app-card [hidHeader]="true">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="far fa-id-card text-info mr-2"></i> Cartão Visita Padrão
                    </h2>
                  </div>
                </div>
              </app-card>
            </div>

            <div class="col-xl-4 col-md-6" (click)="cartaoVisitaD()">
              <app-card [hidHeader]="true">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="far fa-id-card text-info mr-2"></i> Template Livre
                    </h2>
                  </div>
                </div>
              </app-card>
            </div>

            <div class="col-sm-12 centered-content">
              <div class="btn-group mt-10">
                <!--<button type="button" class="btn btn-primary btn-sm" awNextStep>Próximo</button>-->
                <button type="button" class="btn btn-primary " awNextStep>Próximo <i class="fa fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>
        </aw-wizard-step>
        <aw-wizard-step stepTitle="Formulario" [navigationSymbol]="{ symbol: '&#xe88c;', fontFamily: 'feather' }"
          [style.font-weight]="900">
          <ng-template awWizardStepSymbol>
            <i class="far fa-file"></i>
          </ng-template>
          <div class="row">

            <div class="col-12" *ngIf="tipoQrcode == 'link'">
              <div class="form-group">
                <label>Titulo QRCode</label>
                <input type="text" class="form-control" [(ngModel)]="tituloQrcode" name="tituloQrcode" placeholder="">
              </div>
              <div class="form-group">
                <label>URL</label>
                <input type="text" class="form-control" [(ngModel)]="urlQrcode" name="urlQrcode" placeholder="https://">
              </div>
            </div>

            <div class="col-12" *ngIf="tipoQrcode == 'mapa'">
              <div class="form-group">
                <label>Titulo QRCode</label>
                <input type="text" class="form-control" [(ngModel)]="tituloQrcode" name="tituloQrcode" placeholder="">
              </div>
              <div class="row">
                <div class="form-group col-md-8 col-xl-8 col-sm-12">
                  <label>Rua</label>
                  <input type="text" class="form-control" [(ngModel)]="rua" name="rua">
                </div>
                <div class="form-group col-md-4 col-xl-4 col-sm-12">
                  <label>Número</label>
                  <input type="text" class="form-control" [(ngModel)]="numero" name="numero">
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-4 col-xl-4 col-sm-12">
                  <label>Bairro </label>
                  <input type="text" class="form-control" [(ngModel)]="bairro" name="bairro">
                </div>
                <div class="form-group col-md-4 col-xl-4 col-sm-12">
                  <label>Cidade</label>
                  <input type="text" class="form-control" [(ngModel)]="cidade" name="cidade">
                </div>
                <div class="form-group col-md-4 col-xl-4 col-sm-12">
                  <label>Estado</label>
                  <input type="text" class="form-control" [(ngModel)]="estado" name="estado">
                </div>
              </div>

            </div>

            <div class="col-12" *ngIf="tipoQrcode == 'texto'">
              <div class="form-group">
                <label for="exampleInputEmail1">Titulo QRCode</label>
                <input type="text" class="form-control" aria-describedby="emailHelp" placeholder=""
                  [(ngModel)]="tituloQrcode" name="tituloQrcode">
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Texto</label>
                <!--<textarea class="form-control"  rows="3" [(ngModel)]="mensagemQrcode" name="mensagemQrcode"></textarea>-->
                <app-tinymce [(ngModel)]='mensagemQrcode' #tinymce='tinymce' name="mensagemQrcode"></app-tinymce>
              </div>
            </div>

            <div class="col-12" *ngIf="tipoQrcode == 'pdf' || tipoQrcode == 'arquivo' || tipoQrcode == 'mp3'">
              <div class="form-group">
                <label for="exampleInputEmail1">Titulo QRCode</label>
                <input type="text" class="form-control" aria-describedby="emailHelp" placeholder=""
                  [(ngModel)]="tituloQrcode" name="tituloQrcode">
              </div>

              <div class="form-group">
                <label class=" control-label">Selecione se é exibição ou Download</label>
                <select class="form-control" [(ngModel)]='exibicaoDownload'>
                  <option value="78DE2DA5-590F-4459-9140-D4E433CF089B">Arquivo Modo Exibição </option>
                  <option value="78DE2DA5-590F-4459-9140-D4E433CF089C">Arquivo Modo Download </option>
                </select>
              </div>

              <div align="center">
                <div class="form-group col-6" *ngIf="tipoQrcode == 'pdf'">
                  <label for="exampleInputEmail1">Selecione o PDF</label>
                  <file-upload [(ngModel)]="uploadedFilePdF" [fileslimit]="1" simple accept="application/pdf">
                  </file-upload>
                </div>
                <div class="form-group col-6" *ngIf="tipoQrcode == 'arquivo'">
                  <label for="exampleInputEmail1">Selecione o Arquivo</label>
                  <file-upload [(ngModel)]="uploadedFilePdF" [fileslimit]="1" simple></file-upload>
                </div>
                <div class="form-group col-6" *ngIf="tipoQrcode == 'mp3'">
                  <label for="exampleInputEmail1">Selecione o MP3</label>
                  <file-upload [(ngModel)]="uploadedFilePdF" [fileslimit]="1" simple accept="audio/*"></file-upload>
                </div>
              </div>

            </div>

            <div class="col-12" *ngIf="tipoQrcode == 'conteudo'">
              <div class="form-group ">
                <label for="exampleInputEmail1">Titulo QRCode</label>
                <input type="text" class="form-control" aria-describedby="emailHelp" placeholder=""
                  [(ngModel)]="tituloQrcode" name="tituloQrcode">
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Conteúdo</label>
                <app-tinymce [(ngModel)]='mensagemQrcode' #tinymce='tinymce' name="mensagemQrcode"></app-tinymce>
              </div>
            </div>

            <div class="col-12" *ngIf="tipoQrcode == 'email'">
              <div class="form-group">
                <label for="exampleInputEmail1">Titulo QRCode</label>
                <input type="text" class="form-control" aria-describedby="emailHelp" placeholder=""
                  [(ngModel)]="tituloQrcode" name="tituloQrcode">
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Email</label>
                <input type="text" class="form-control" aria-describedby="emailHelp" placeholder="">
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Assunto</label>
                <input type="text" class="form-control" aria-describedby="emailHelp" placeholder="">
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Mensagem</label>
                <textarea class="form-control" rows="3"></textarea>
              </div>
            </div>

            <div class="col-12" *ngIf="tipoQrcode == 'telefone'">
              <div class="form-group">
                <label for="exampleInputEmail1">Titulo QRCode</label>
                <input type="text" class="form-control" aria-describedby="emailHelp" placeholder=""
                  [(ngModel)]="tituloQrcode" name="tituloQrcode">
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Número de Telefone</label>
                <input type="text" class="form-control" [textMask]="{mask: maskTeleArea}" aria-describedby="emailHelp"
                  placeholder="(99) 9 9999-9999" [(ngModel)]="urlQrcode" name="urlQrcode">
              </div>
            </div>

            <div class="col-12" *ngIf="tipoQrcode == 'whatsapp'">
              <div class="form-group">
                <label for="exampleInputEmail1">Titulo QRCode</label>
                <input type="text" class="form-control" aria-describedby="emailHelp" placeholder=""
                  [(ngModel)]="tituloQrcode" name="tituloQrcode">
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Número de Telefone</label>
                <input type="text" class="form-control" [textMask]="{mask: maskTeleArea}" aria-describedby="emailHelp"
                  placeholder="(99) 9 9999-9999" [(ngModel)]="urlQrcode" name="urlQrcode">
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Mensagem</label>
                <textarea class="form-control" [(ngModel)]="mensagemQrcode" name="mensagemQrcode" rows="3"></textarea>
              </div>
            </div>

            <div class="col-12" *ngIf="tipoQrcode == 'telegram'">
              <div class="form-group">
                <label for="exampleInputEmail1">Titulo QRCode</label>
                <input type="text" class="form-control" aria-describedby="emailHelp" placeholder=""
                  [(ngModel)]="tituloQrcode" name="tituloQrcode">
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Username</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroupPrepend">https://t.me/</span>
                  </div>
                  <input type="text" class="form-control" placeholder="" [(ngModel)]="urlQrcode" name="urlQrcode">
                </div>
              </div>
            </div>

            <!--<div class="col-12" *ngIf="tipoQrcode == 'skype'">
              <div class="form-group">
                <label for="exampleInputEmail1">Titulo QRCode</label>
                <input type="text" class="form-control" aria-describedby="emailHelp" placeholder=""
                  [(ngModel)]="tituloQrcode" name="tituloQrcode">
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Numero do Telefone ou Usuário do Skype</label>
                <input type="text" class="form-control" aria-describedby="emailHelp" placeholder=""
                  [(ngModel)]="urlQrcode" name="urlQrcode">
              </div>
            </div>-->

            <div class="col-12" *ngIf="tipoQrcode == 'facebook'">
              <div class="form-group">
                <label for="exampleInputEmail1">Titulo QRCode</label>
                <input type="text" class="form-control" aria-describedby="emailHelp" placeholder=""
                  [(ngModel)]="tituloQrcode" name="tituloQrcode">
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1"> Usuário do Facebook</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroupPrepend">https://facebook.com/</span>
                  </div>
                  <input type="text" class="form-control" placeholder="" [(ngModel)]="urlQrcode" name="urlQrcode">
                </div>
              </div>
            </div>

            <div class="col-12" *ngIf="tipoQrcode == 'twitter'">
              <div class="form-group">
                <label for="exampleInputEmail1">Titulo QRCode</label>
                <input type="text" class="form-control" aria-describedby="emailHelp" placeholder=""
                  [(ngModel)]="tituloQrcode" name="tituloQrcode">
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1"> Usuário do Twitter</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroupPrepend">https://twitter.com/</span>
                  </div>
                  <input type="text" class="form-control" placeholder="" [(ngModel)]="urlQrcode" name="urlQrcode">
                </div>
              </div>
            </div>

            <div class="col-12" *ngIf="tipoQrcode == 'youtube'">
              <div class="form-group">
                <label for="exampleInputEmail1">Titulo QRCode</label>
                <input type="text" class="form-control" aria-describedby="emailHelp" placeholder=""
                  [(ngModel)]="tituloQrcode" name="tituloQrcode">
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1"> Usuário do Youtube</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroupPrepend">https://www.youtube.com/</span>
                  </div>
                  <input type="text" class="form-control" placeholder="" [(ngModel)]="urlQrcode" name="urlQrcode">
                </div>
              </div>


            </div>


            <div class="col-12" *ngIf="tipoQrcode == 'linkedin'">
              <div class="form-group">
                <label for="exampleInputEmail1">Titulo QRCode</label>
                <input type="text" class="form-control" aria-describedby="emailHelp" placeholder=""
                  [(ngModel)]="tituloQrcode" name="tituloQrcode">
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1"> Usuário do Linkedin</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroupPrepend">https://linkedin.com/in/</span>
                  </div>
                  <input type="text" class="form-control" placeholder="" [(ngModel)]="urlQrcode" name="urlQrcode">
                </div>
              </div>
            </div>

            <div class="col-12" *ngIf="tipoQrcode == 'google'">
              <div class="form-group">
                <label for="exampleInputEmail1">Titulo QRCode</label>
                <input type="text" class="form-control" aria-describedby="emailHelp" placeholder=""
                  [(ngModel)]="tituloQrcode" name="tituloQrcode">
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1"> Email do google</label>
                <input type="text" class="form-control" aria-describedby="emailHelp" placeholder=""
                  [(ngModel)]="urlQrcode" name="urlQrcode">
              </div>
            </div>

            <div class="col-12" *ngIf="tipoQrcode == 'instagram'">
              <div class="form-group">
                <label for="exampleInputEmail1">Titulo QRCode</label>
                <input type="text" class="form-control" aria-describedby="emailHelp" placeholder=""
                  [(ngModel)]="tituloQrcode" name="tituloQrcode">
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1"> Usuário do instagram</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroupPrepend">@</span>
                  </div>
                  <input type="text" class="form-control" placeholder="" [(ngModel)]="urlQrcode" name="urlQrcode">
                </div>
              </div>

            </div>

            <div class="col-12 centered-content">
              <div class="btn-group mt-10">

                <button type="button" class="btn btn-secondary " awPreviousStep> <i class="fa fa-chevron-left"></i>
                  Voltar
                </button>
                <button type="button" class="btn btn-primary " (click)="wizardFinalizar()">Próximo <i
                    class="fa fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>
        </aw-wizard-step>
        <aw-wizard-step stepTitle="Finalizar" [navigationSymbol]="{ symbol: '&#xe840;', fontFamily: 'feather' }">
          <div class="row">

            <!--<div class="col-12 ">
              <h5>EScolha a cor do QRCODE </h5>
              <hr>
              <div class="row">
                <div class="col-md-6">
                  <div class="input-group mb-3 col-md-6">
                    <input class="form-control" [(colorPicker)]="showColorCode" [style.background]="showColorCode"
                      [value]="showColorCode" [cpPosition]="'bottom'" />
                    <div class="input-group-append">
                      <button type="button" class="btn btn-primary" (click)="atualizar()">
                        <i class="feather icon-refresh-ccw"></i>
                        Aplicar</button>
                    </div>
                  </div>

                  <div class="col-md-11">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Link</label>
                      <input type="text" disabled [(ngModel)]="qrcode" class="form-control"
                        aria-describedby="emailHelp" placeholder="">
                    </div>
                  </div>
                  <div class="col-md-11">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Imagem centro</label>
                      <input type="text" [(ngModel)]="imagemCentro" class="form-control"
                        aria-describedby="emailHelp" placeholder="">
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <canvas id="canvas"></canvas>
                </div>

              </div>

            </div>-->

            <!--<img src="" alt="" id="image" /> <img id="image" alt="">-->


            <div class="col-sm-12 centered-content">
              <div class="btn-group mt-10">
                <button type="button" class="btn btn-secondary " awPreviousStep> <i class="fa fa-chevron-left"></i>
                  Voltar
                </button>
                <button type="button" class="btn btn-success " (click)="salvar()"> Criar QRCODE <i
                    class="fa fa-qrcode"></i>
                </button>
              </div>
            </div>
          </div>
        </aw-wizard-step>
      </aw-wizard>
    </app-card>
  </div>

  <!--<div class="col-sm-12">
    <h5>Vamos Cadastrar um QrCode</h5>
    <ngb-accordion [closeOthers]="true" activeIds="static-1" class="gradientaccordion">
      <ngb-panel id="static-1">
        <ng-template ngbPanelTitle>
          <h5><a href="javascript:">Escolha o Tipo de QrCode para Continuar</a></h5>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="row">


            <div class="col-xl-3 col-md-5">
              <app-card [hidHeader]="true">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="fas fa-globe text-info mr-2"></i> Link </h2>
                  </div>
                </div>
              </app-card>
            </div>

            <div class="col-xl-3 col-md-5">
              <app-card [hidHeader]="true">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="fas fa-pencil-alt text-info mr-2"></i> Texto </h2>
                  </div>
                </div>
              </app-card>
            </div>

            <div class="col-xl-3 col-md-5">
              <app-card [hidHeader]="true">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="far fa-file-pdf text-info mr-2"></i> PDF </h2>
                  </div>
                </div>
              </app-card>
            </div>

            <div class="col-xl-3 col-md-5">
              <app-card [hidHeader]="true">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="far fa-file-alt text-info mr-2"></i> Conteudo </h2>
                  </div>
                </div>
              </app-card>
            </div>

            <div class="col-xl-3 col-md-5">
              <app-card [hidHeader]="true">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="fas fa-podcast text-info mr-2"></i> PodCast </h2>
                  </div>
                </div>
              </app-card>
            </div>

            <div class="col-xl-3 col-md-5">
              <app-card [hidHeader]="true">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="far fa-envelope text-info mr-2"></i> Email </h2>
                  </div>
                </div>
              </app-card>
            </div>

            <div class="col-xl-3 col-md-5">
              <app-card [hidHeader]="true">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="feather icon-phone text-info mr-2"></i> Telefone </h2>
                  </div>
                </div>
              </app-card>
            </div>

            <div class="col-xl-3 col-md-5">
              <app-card [hidHeader]="true">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="fab fa-whatsapp text-info mr-2"></i> Whatsapp </h2>
                  </div>
                </div>
              </app-card>
            </div>

            <div class="col-xl-3 col-md-5">
              <app-card [hidHeader]="true">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="fab fa-telegram text-info mr-2"></i> Telegram </h2>
                  </div>
                </div>
              </app-card>
            </div>

            <div class="col-xl-3 col-md-5">
              <app-card [hidHeader]="true">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="fab fa-skype text-info mr-2"></i> Skype </h2>
                  </div>
                </div>
              </app-card>
            </div>

            <div class="col-xl-3 col-md-5">
              <app-card [hidHeader]="true">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="fab fa-facebook-f text-info mr-2"></i> Facebook </h2>
                  </div>
                </div>
              </app-card>
            </div>

            <div class="col-xl-3 col-md-5">
              <app-card [hidHeader]="true">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="fab fa-twitter text-info mr-2"></i> Twitter </h2>
                  </div>
                </div>
              </app-card>
            </div>

            <div class="col-xl-3 col-md-5">
              <app-card [hidHeader]="true">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="fab fa-youtube text-info mr-2"></i> Youtube </h2>
                  </div>
                </div>
              </app-card>
            </div>

            <div class="col-xl-3 col-md-5">
              <app-card [hidHeader]="true">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="fab fa-linkedin-in text-info mr-2"></i> Linkedin </h2>
                  </div>
                </div>
              </app-card>
            </div>

            <div class="col-xl-3 col-md-5">
              <app-card [hidHeader]="true">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="fab fa-google-plus-g text-info mr-2"></i> Google + </h2>
                  </div>
                </div>
              </app-card>
            </div>

            <div class="col-xl-3 col-md-5">
              <app-card [hidHeader]="true">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="fab fa-instagram text-info mr-2"></i> Instagram </h2>
                  </div>
                </div>
              </app-card>
            </div>

            <div class="col-xl-4 col-md-6" (click)="cartaoVisita()">
              <app-card [hidHeader]="true">
                <h6 class="mb-0 float-right"></h6>
                <span class="d-block mb-1">&nbsp;</span>
                <div class="row align-items-center justify-content-left">
                  <div class="col-auto">
                    <h2 class="text-muted  m-0 text-size"><i class="far fa-id-card text-info mr-2"></i> Cartão Visita </h2>
                  </div>
                </div>
              </app-card>
            </div>

          </div>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="static-2">
        <ng-template ngbPanelTitle>
          <h5><a href="javascript:">Entre com as informações necessárias</a></h5>
        </ng-template>
        <ng-template ngbPanelContent>
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon
          officia
          aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
          tempor,
          sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh
          helvetica,
          craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.
          Leggings
          occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them
          accusamus
          labore sustainable VHS.
        </ng-template>
      </ngb-panel>
      <ngb-panel id="static-3">
        <ng-template ngbPanelTitle>
          <h5><a href="javascript:">Collapsible Group Item #3</a></h5>
        </ng-template>
        <ng-template ngbPanelContent>
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon
          officia
          aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
          tempor,
          sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh
          helvetica,
          craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.
          Leggings
          occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them
          accusamus
          labore sustainable VHS.
        </ng-template>
      </ngb-panel>
    </ngb-accordion>

  </div>-->
  <!--<div class="col-sm-12">
    <app-card cardTitle="Basic Collapse" [options]="false">
      <button class="btn btn-primary" type="button" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">Button with data-target</button>
      <div id="collapseExample" [ngbCollapse]="isCollapsed">
        <p class="mt-3">{{isCollapsed}} Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. </p>
      </div>
    </app-card>
  </div>-->
  <!--<div class="col-sm-12">
    <h5>Escolha o Tipo de QrCode para Continuar</h5>
    <hr>
    <app-card [hidHeader]="true" cardClass="text-center">
      <div class="row">


        <div class="col-xl-3 col-md-5">
          <app-card [hidHeader]="true">
            <h6 class="mb-0 float-right"></h6>
            <span class="d-block mb-1">&nbsp;</span>
            <div class="row align-items-center justify-content-left">
              <div class="col-auto">
                <h2 class="text-muted  m-0 text-size"><i class="fas fa-globe text-info mr-2"></i> Link </h2>
              </div>
            </div>
          </app-card>
        </div>

        <div class="col-xl-3 col-md-5">
          <app-card [hidHeader]="true">
            <h6 class="mb-0 float-right"></h6>
            <span class="d-block mb-1">&nbsp;</span>
            <div class="row align-items-center justify-content-left">
              <div class="col-auto">
                <h2 class="text-muted  m-0 text-size"><i class="fas fa-pencil-alt text-info mr-2"></i> Texto </h2>
              </div>
            </div>
          </app-card>
        </div>

        <div class="col-xl-3 col-md-5">
          <app-card [hidHeader]="true">
            <h6 class="mb-0 float-right"></h6>
            <span class="d-block mb-1">&nbsp;</span>
            <div class="row align-items-center justify-content-left">
              <div class="col-auto">
                <h2 class="text-muted  m-0 text-size"><i class="far fa-file-pdf text-info mr-2"></i> PDF </h2>
              </div>
            </div>
          </app-card>
        </div>

        <div class="col-xl-3 col-md-5">
          <app-card [hidHeader]="true">
            <h6 class="mb-0 float-right"></h6>
            <span class="d-block mb-1">&nbsp;</span>
            <div class="row align-items-center justify-content-left">
              <div class="col-auto">
                <h2 class="text-muted  m-0 text-size"><i class="far fa-file-alt text-info mr-2"></i> Conteudo </h2>
              </div>
            </div>
          </app-card>
        </div>

        <div class="col-xl-3 col-md-5">
          <app-card [hidHeader]="true">
            <h6 class="mb-0 float-right"></h6>
            <span class="d-block mb-1">&nbsp;</span>
            <div class="row align-items-center justify-content-left">
              <div class="col-auto">
                <h2 class="text-muted  m-0 text-size"><i class="fas fa-podcast text-info mr-2"></i> PodCast </h2>
              </div>
            </div>
          </app-card>
        </div>

        <div class="col-xl-3 col-md-5">
          <app-card [hidHeader]="true">
            <h6 class="mb-0 float-right"></h6>
            <span class="d-block mb-1">&nbsp;</span>
            <div class="row align-items-center justify-content-left">
              <div class="col-auto">
                <h2 class="text-muted  m-0 text-size"><i class="far fa-envelope text-info mr-2"></i> Email </h2>
              </div>
            </div>
          </app-card>
        </div>

        <div class="col-xl-3 col-md-5">
          <app-card [hidHeader]="true">
            <h6 class="mb-0 float-right"></h6>
            <span class="d-block mb-1">&nbsp;</span>
            <div class="row align-items-center justify-content-left">
              <div class="col-auto">
                <h2 class="text-muted  m-0 text-size"><i class="feather icon-phone text-info mr-2"></i> Telefone </h2>
              </div>
            </div>
          </app-card>
        </div>

        <div class="col-xl-3 col-md-5">
          <app-card [hidHeader]="true">
            <h6 class="mb-0 float-right"></h6>
            <span class="d-block mb-1">&nbsp;</span>
            <div class="row align-items-center justify-content-left">
              <div class="col-auto">
                <h2 class="text-muted  m-0 text-size"><i class="fab fa-whatsapp text-info mr-2"></i> Whatsapp </h2>
              </div>
            </div>
          </app-card>
        </div>

        <div class="col-xl-3 col-md-5">
          <app-card [hidHeader]="true">
            <h6 class="mb-0 float-right"></h6>
            <span class="d-block mb-1">&nbsp;</span>
            <div class="row align-items-center justify-content-left">
              <div class="col-auto">
                <h2 class="text-muted  m-0 text-size"><i class="fab fa-telegram text-info mr-2"></i> Telegram </h2>
              </div>
            </div>
          </app-card>
        </div>

        <div class="col-xl-3 col-md-5">
          <app-card [hidHeader]="true">
            <h6 class="mb-0 float-right"></h6>
            <span class="d-block mb-1">&nbsp;</span>
            <div class="row align-items-center justify-content-left">
              <div class="col-auto">
                <h2 class="text-muted  m-0 text-size"><i class="fab fa-skype text-info mr-2"></i> Skype </h2>
              </div>
            </div>
          </app-card>
        </div>

        <div class="col-xl-3 col-md-5">
          <app-card [hidHeader]="true">
            <h6 class="mb-0 float-right"></h6>
            <span class="d-block mb-1">&nbsp;</span>
            <div class="row align-items-center justify-content-left">
              <div class="col-auto">
                <h2 class="text-muted  m-0 text-size"><i class="fab fa-facebook-f text-info mr-2"></i> Facebook </h2>
              </div>
            </div>
          </app-card>
        </div>

        <div class="col-xl-3 col-md-5">
          <app-card [hidHeader]="true">
            <h6 class="mb-0 float-right"></h6>
            <span class="d-block mb-1">&nbsp;</span>
            <div class="row align-items-center justify-content-left">
              <div class="col-auto">
                <h2 class="text-muted  m-0 text-size"><i class="fab fa-twitter text-info mr-2"></i> Twitter </h2>
              </div>
            </div>
          </app-card>
        </div>

        <div class="col-xl-3 col-md-5">
          <app-card [hidHeader]="true">
            <h6 class="mb-0 float-right"></h6>
            <span class="d-block mb-1">&nbsp;</span>
            <div class="row align-items-center justify-content-left">
              <div class="col-auto">
                <h2 class="text-muted  m-0 text-size"><i class="fab fa-youtube text-info mr-2"></i> Youtube </h2>
              </div>
            </div>
          </app-card>
        </div>

        <div class="col-xl-3 col-md-5">
          <app-card [hidHeader]="true">
            <h6 class="mb-0 float-right"></h6>
            <span class="d-block mb-1">&nbsp;</span>
            <div class="row align-items-center justify-content-left">
              <div class="col-auto">
                <h2 class="text-muted  m-0 text-size"><i class="fab fa-linkedin-in text-info mr-2"></i> Linkedin </h2>
              </div>
            </div>
          </app-card>
        </div>

        <div class="col-xl-3 col-md-5">
          <app-card [hidHeader]="true">
            <h6 class="mb-0 float-right"></h6>
            <span class="d-block mb-1">&nbsp;</span>
            <div class="row align-items-center justify-content-left">
              <div class="col-auto">
                <h2 class="text-muted  m-0 text-size"><i class="fab fa-google-plus-g text-info mr-2"></i> Google + </h2>
              </div>
            </div>
          </app-card>
        </div>

        <div class="col-xl-3 col-md-5">
          <app-card [hidHeader]="true">
            <h6 class="mb-0 float-right"></h6>
            <span class="d-block mb-1">&nbsp;</span>
            <div class="row align-items-center justify-content-left">
              <div class="col-auto">
                <h2 class="text-muted  m-0 text-size"><i class="fab fa-instagram text-info mr-2"></i> Instagram </h2>
              </div>
            </div>
          </app-card>
        </div>

        <div class="col-xl-4 col-md-6" (click)="cartaoVisita()">
          <app-card [hidHeader]="true">
            <h6 class="mb-0 float-right"></h6>
            <span class="d-block mb-1">&nbsp;</span>
            <div class="row align-items-center justify-content-left">
              <div class="col-auto">
                <h2 class="text-muted  m-0 text-size"><i class="far fa-id-card text-info mr-2"></i> Cartão Visita </h2>
              </div>
            </div>
          </app-card>
        </div>

      </div>
    </app-card>
  </div>-->
</div>
