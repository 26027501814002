import { ResponseUser } from './../../model/User.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { WizardComponent } from 'angular-archwizard';
import QrCodeWithLogo from "qrcode-with-logos";
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API, SUBTIPOS } from 'src/environments/environment';




@Component({
  selector: 'app-escolha-tipo-qrcode',
  templateUrl: './escolha-tipo-qrcode.component.html',
  styleUrls: ['./escolha-tipo-qrcode.component.scss']
})

export class EscolhaTipoQrcodeComponent implements OnInit {

  @ViewChild(WizardComponent)
  public wizard: WizardComponent;

  public tipoQrcode: string;
  public maskTeleArea = ['(', /\d/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public showColorCode = '#161515';

  public qrcode = 'https://www.microsoft.com';
  public imagemCentro = 'https://www.microsoft.com/favicon.ico?v2';

  public tituloQrcode: string;
  public urlQrcode: string;
  public mensagemQrcode: string;
  public uploadedFilePdF: File[];
  public exibicaoDownload: string;

  public rua: string;
  public numero: number;
  public bairro: string;
  public estado: string;
  public cidade: string;
  public cep: string;

  public user: ResponseUser;

  constructor(
    private router: Router,
    private util: UtilService,
    private ajax: AjaxService,
    private auth: AuthService
  ) {

  }

  ngOnInit() {
    this.user = this.auth.getUser();
    this.exibicaoDownload = "78DE2DA5-590F-4459-9140-D4E433CF089B";
  }

  validarFormLink() {
    if (this.tituloQrcode == undefined || this.tituloQrcode == "") {
      this.util.toasterShowError("Atenção", "Entre com o Título do QrCode");
      return false;
    }
    if (this.tipoQrcode == "mapa") {
      if (this.rua == undefined || this.rua == "") {
        this.util.toasterShowError("Atenção", "Entre com nome da Rua");
        return false;
      }
      if (this.numero == undefined || this.numero == 0) {
        this.util.toasterShowError("Atenção", "Entre com Número");
        return false;
      }
      if (this.bairro == undefined || this.bairro == "") {
        this.util.toasterShowError("Atenção", "Entre com o Bairro");
        return false;
      }
      if (this.cidade == undefined || this.cidade == "") {
        this.util.toasterShowError("Atenção", "Entre com a Cidade");
        return false;
      }
      if (this.estado == undefined || this.estado == "") {
        this.util.toasterShowError("Atenção", "Entre com o Estado");
        return false;
      }
    } else {

      if (this.urlQrcode == undefined || this.urlQrcode == "") {
        this.util.toasterShowError("Atenção", "Entre com o Link ou usuário correspondente");
        return false;
      }
    }



    if (this.tipoQrcode == "whatsapp") {
      if (this.mensagemQrcode == undefined || this.mensagemQrcode == "") {
        this.util.toasterShowError("Atenção", "Entre com uma mensagem");
        return false;
      }
    }

    return true;
  }

  validarFormTextoConteudo() {
    if (this.tituloQrcode == undefined || this.tituloQrcode == "") {
      this.util.toasterShowError("Atenção", "Entre com o Título do QrCode");
      return false;
    }

    if (this.mensagemQrcode == undefined || this.mensagemQrcode == "") {
      this.util.toasterShowError("Atenção", "Entre com uma mensagem");
      return false;
    }

    return true;
  }

  validarFormPdf() {
    if (this.tituloQrcode == undefined || this.tituloQrcode == "") {
      this.util.toasterShowError("Atenção", "Entre com o Título do QrCode");
      return false;
    }

    if (this.uploadedFilePdF == null) {
      this.util.toasterShowError("Atenção", "Necessário escolher um arquivo");
      return false;
    }


    return true;
  }

  salvarQrTipoArquivo() {
    //"TEXTO / CONTEUDO"   
    debugger
    let urlConteudo = this.mensagemQrcode;
    let subtitulo = SUBTIPOS.find(it => it.nome == (this.tipoQrcode.toLowerCase() == "link" ? "comum" : this.tipoQrcode.toLowerCase()));

    debugger
    let dados = {
      "idTipoQrCode": this.exibicaoDownload,
      "nome": this.tituloQrcode,
      "ativo": true,
      "conteudo": urlConteudo,
      "idSubtipoQrCode": subtitulo.id

    }

    let formData = this.util.jsonToFormData(dados);
    formData.append('ConteudoArquivo', this.uploadedFilePdF[0], this.uploadedFilePdF[0].name);
    /*Array.from(this.uploadedFilePdF).map((file, index) => {
      return formData.append('ConteudoArquivo' + index, file, file.name);
    });*/

    let url = API + "User/" + this.user.user.id + "/QrCode/File";
    this.util.showLoading();
    this.ajax.postFile(url, formData)
      .subscribe((resp) => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowSuccess("Sucesso", "Cadastro com sucesso!");
        this.router.navigate(['/home']);

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", err.message);
      });
  }


  salvarQrTipoTextoConteudo() {
    //"TEXTO / CONTEUDO"   
    debugger
    let urlConteudo = this.mensagemQrcode;
    let subtitulo = SUBTIPOS.find(it => it.nome == (this.tipoQrcode.toLowerCase() == "link" ? "comum" : this.tipoQrcode.toLowerCase()));

    let dados = {
      "idTipoQrCode": "9388B14B-A27F-445D-A362-0E4A6DD63BAA",
      "nome": this.tituloQrcode,
      "ativo": true,
      "conteudo": urlConteudo,
      "idSubtipoQrCode": subtitulo.id
    }

    let url = API + "User/" + this.user.user.id + "/QrCode";
    this.util.showLoading();
    this.ajax.post(url, dados)
      .subscribe((resp) => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowSuccess("Sucesso", "Cadastro com sucesso!");
        this.router.navigate(['/home']);

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", err.message);
      });
  }

  salvarQrTipoLink() {
    //"Redirecionar"
    let subtitulo = SUBTIPOS.find(it => it.nome == (this.tipoQrcode.toLowerCase() == "link" ? "comum" : this.tipoQrcode.toLowerCase()));

    if (this.tipoQrcode == "mapa")
      this.urlQrcode = this.rua + "," + this.numero + "," + this.bairro + "," + this.cidade + "," + this.estado;

    let urlConteudo = this.util.montarUrl(this.tipoQrcode, this.urlQrcode, this.mensagemQrcode);

    debugger
    let dados = {
      "idTipoQrCode": "b4fd7d17-e66c-4c3f-a635-f140eb695db3",
      "nome": this.tituloQrcode,
      "ativo": true,
      "conteudo": urlConteudo,
      "idSubtipoQrCode": subtitulo.id
    }
    let url = API + "User/" + this.user.user.id + "/QrCode";
    this.util.showLoading();
    this.ajax.post(url, dados)
      .subscribe((resp) => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowSuccess("Sucesso", "Cadastro com sucesso!");
        this.router.navigate(['/home']);

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", err.message);
      });
  }

  salvar() {

    debugger
    switch (this.tipoQrcode) {
      case "link":
      case "mapa":
      case "whatsapp":
      case "telegram":
      case "facebook":
      case "twitter":
      case "youtube":
      case "linkedin":
      case "telefone":
      case "instagram": {
        this.salvarQrTipoLink();
        break;
      }
      case "texto":
      case "conteudo": {
        //console.log(this.mensagemQrcode);
        this.salvarQrTipoTextoConteudo();
        break;
      }
      case "pdf":
      case "arquivo":
      case "mp3": {
        //console.log(this.mensagemQrcode);
        this.salvarQrTipoArquivo();
        break;
      }
      default: {
        //statements; 
        break;
      }
    }
  }

  validarForm() {

    if (this.tipoQrcode == "link"
      || this.tipoQrcode == "mapa"
      || this.tipoQrcode == "whatsapp"
      || this.tipoQrcode == "telegram"
      || this.tipoQrcode == "facebook"
      || this.tipoQrcode == "twitter"
      || this.tipoQrcode == "youtube"
      || this.tipoQrcode == "linkedin"
      || this.tipoQrcode == "instagram"
      || this.tipoQrcode == "telefone"
    ) {
      this.qrcode = this.urlQrcode;
      return this.validarFormLink();
    } else
      if (this.tipoQrcode == "texto"
        || this.tipoQrcode == "conteudo"
      ) {
        return this.validarFormTextoConteudo();
      } else
        if (this.tipoQrcode == "pdf"
          || this.tipoQrcode == "arquivo"
          || this.tipoQrcode == "mp3"
        ) {
          return this.validarFormPdf();
        }

    return true;
  }

  wizardFinalizar() {
    if (this.validarForm()) {
      this.wizard.goToNextStep();
      this.gerarQrcode();
    }
  }


  cartaoVisita() {
    this.router.navigate(['/cadastrar-qrcode']);
  }

  cartaoVisitaD() {
    this.router.navigate(['/cadastrar-cartao-visita']);
  }

  cartaoFormulario() {
    this.router.navigate(['/cadastrar-qrcode-entrada']);
  }

  notImplement(name) {
    this.tipoQrcode = name;
    this.util.notImplement();
  }
  escolhaRadio(name) {
    this.tipoQrcode = name;
  }

  atualizar() {
    this.gerarQrcode();
  }

  isCanvas(obj: HTMLCanvasElement | HTMLElement): obj is HTMLCanvasElement {
    return obj.tagName === 'CANVAS';
  }

  gerarQrcode() {
    this.qrcode = this.util.montarUrl(this.tipoQrcode, this.urlQrcode, this.mensagemQrcode);
    var canvas = document.getElementById('canvas');
    if (this.isCanvas(canvas)) {

      let qrcode = new QrCodeWithLogo({
        canvas: canvas,
        content: this.qrcode,
        width: 250,
        download: false,
        nodeQrCodeOptions: { color: { dark: this.showColorCode } },
        logo: {
          src: this.imagemCentro
        }
      }).toImage();
    }

  }

}
