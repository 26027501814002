

export class DadosTemplateEntrada {
  idTipoEntrada: string;
  chave: string;
  nomeCampo: string;
  valor: string;
  ordem: number;
  obrigatorio: boolean;
  valores: string[];
} 

