<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Aguarde ...</p>
  </ngx-spinner>
<div class="auth-wrapper">
    <div class="blur-bg-images"></div>
    <!-- [ change-password ] start -->
    <div class="auth-content">
      <div class="card">
        <div class="row align-items-center">
          <div class="col-md-12">
            <div class="card-body">
              <img src="assets/images/logo-dark.pngs" alt="" class="img-fluid mb-4">
              <h4 class="mb-4 f-w-400">Cadastro de Usuario</h4>
              <div class="input-group mb-2">
                <input type="text" class="form-control" placeholder="Nome" [(ngModel)]="nome"  name="nome">
              </div>
              <div class="input-group mb-2">
                <input type="email" class="form-control" placeholder="Email" [(ngModel)]="email"  name="email">
              </div>
              <div class="input-group mb-2">          
                <input type="text" class="form-control" [textMask]="{mask: maskTeleArea}" 
                  aria-describedby="emailHelp" placeholder="(99) 9 9999-9999" [(ngModel)]="telefone"  name="telefone">
              </div>
              <div class="input-group mb-2">
                <input type="password" class="form-control" placeholder="Senha" [(ngModel)]="senha"  name="senha">
              </div>
              <div class="input-group mb-2">
                <input type="password" class="form-control" placeholder="Confirmação de Senha" [(ngModel)]="confirmacaoSenha"  name="confirmacaoSenha">
              </div>
              <button class="btn btn-block btn-primary mb-4" (click)="cadastrar()">Cadastrar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- [ change-password ] end -->
  </div>