import { EstatisticaComponent } from './page/estatistica/estatistica.component';
import { ConfigComponent } from './page/config/config.component';
import { EditComponent } from './page/edit/edit.component';
import { EscolhaTipoQrcodeComponent } from './page/escolha-tipo-qrcode/escolha-tipo-qrcode.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CadastrarQrcodeComponent } from './page/cadastrar-qrcode/cadastrar-qrcode.component';
import { LoginComponent } from './page/login/login.component';
import { VisualizarCartaoComponent } from './page/visualizar-cartao/visualizar-cartao.component';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { LoggedInGuard } from './page/security/loggedin.quard';
import { HomeComponent } from './page/home/home.component';
import { GComponent } from './page/g/g.component';
import { CadastroUsuarioComponent } from './page/cadastro-usuario/cadastro-usuario.component';
import { CadastroTipoEntradaComponent } from './page/cadastro-tipo-entrada/cadastro-tipo-entrada.component';
import { CadastroCartaoVisitaComponent } from './page/cadastro-cartao-visita/cadastro-cartao-visita.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'sample-page',
        loadChildren: () => import('./demo/pages/sample-page/sample-page.module').then(module => module.SamplePageModule)
      }
      , { path: 'cadastrar-qrcode', component: CadastrarQrcodeComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'cadastrar-cartao-visita', component: CadastroCartaoVisitaComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'cadastrar-cartao-visita/:qr', component: CadastroCartaoVisitaComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
        , { path: 'cadastrar-qrcode-entrada', component: CadastroTipoEntradaComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'home', component: HomeComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'escolha-tipo-qrcode', component: EscolhaTipoQrcodeComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'edit-qrcode/:qr', component: EditComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'config-qrcode/:qr', component: ConfigComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'estatistica/:qr', component: EstatisticaComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
    ]
  }
  ,
  {
    path: '',
    component: AuthComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'cadastro-usuario', component: CadastroUsuarioComponent },
      { path: 'visualizar-cartao', component: VisualizarCartaoComponent },
      { path: 'g/:p', component: GComponent },
      /*{
        path: 'g/:p', component: GComponent,
        data: {
          seo: {
            title: 'Bem Vindo ao QRpig 🐷',
            metaTags: [
              { name: 'description', content: 'É a melhor ferramenta para geração de QrCode Dinamicos, cartao de visita, Cartões dinâmicos' },
              { property: 'og:title', content: 'Bem Vindo ao QRpig 🐷' },
              { property: 'og:type', content: 'website' },
              { property: 'og:site_name', content: 'QRpig' },
              { proprety: 'og:description', content: 'É a melhor ferramenta para geração de QrCode Dinamicos, cartao de visita, Cartões dinâmicos' },
              { property: 'twitter:title', content: 'Bem Vindo ao QRpig 🐷' },
              { proprety: 'twitter:description', content: 'É a melhor ferramenta para geração de QrCode Dinamicos, cartao de visita, Cartões dinâmicos' },

              //{ property: 'og:image', content: environment.appUrl + 'assets/image/characters.png' },
              //{ property: 'og:url', content: environment.appUrl + 'characters' },
              { name: "twitter:card", content: "summary_large_image" },
            ]
          }
        }
      }*/
    ]
  }/*,
  {
    path: '',
    component: AuthComponent,
    children: []
  }*/
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
