import { REDIRECT_SITE } from './../../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Qrcode } from 'src/app/model/Qrcode';
import { AjaxService } from 'src/app/service/ajax.service';
import { UtilService } from 'src/app/service/util.service';
import { API, API_QRCODE, maskCPF, maskTelefoneCelular, numberMaskInt, numberMaskReal } from 'src/environments/environment';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
import { Pipe, PipeTransform } from '@angular/core';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { DomSanitizer } from '@angular/platform-browser';
import { DadosCartao } from 'src/app/model/CartaoVisita';
import { FormControl, FormGroup } from '@angular/forms';
import { GeoLocation } from 'src/app/model/geoLocation';

@Component({
  selector: 'app-g',
  templateUrl: './g.component.html',
  styleUrls: ['./g.component.scss']
})
export class GComponent implements OnInit {

  public addClass:string='';
  public imgLogoPadrao:string='assets/images/logo/new/qrpig-1.png';
  public imgFundoFormularioPadrao:string ="assets/images/logo/new/qrpig-3.png";
  public files: Array<any>=[];
  public myFormGroup: FormGroup;
  public qrCodeEntradaFormTemplate = [];
  public urlRedirectEntradaDados:string = '';
  public mensagemCamposObrigatorios: string;
  public isExibirBotaoSalvar: boolean = true;
  public contadorRedirect: number = 3;
  param: any;
  objQrcode: Qrcode;
  htmlConteudo: string;
  linkArquivo: string;
  mensagemErro: string;
  xmetricid: string;
  ps: string;
  public fundo: DadosCartao = new DadosCartao();
  public musicaFundo: DadosCartao = new DadosCartao();
  public avatar: DadosCartao = new DadosCartao();
  public location: GeoLocation = new GeoLocation();
  public redirect_site = REDIRECT_SITE;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private ajax: AjaxService,
    private util: UtilService,
    private sanitizer: DomSanitizer

  ) {

  }

  ngOnInit() {
    this.param = this.activatedRoute.snapshot.params['p'];
    this.getQrcode();
    this.mensagemErro = "";
    this.ps = "";
    //this.location = this.util.getLocation();
  }

  tiposEntrada = {
    TextoLivre: '1696e7dc-514e-4c31-b713-8bb4e4442ea9'
    , Telefone: '1696e7dc-514e-4c31-b713-8bb4e4442ea8'
    , Cpf: '1696e7dc-514e-4c31-b713-8bb4e4442ea7'
    , Rg: '1696e7dc-514e-4c31-b713-8bb4e4442ea6'
    , Email: '1696e7dc-514e-4c31-b713-8bb4e4442ea5'
    , Moeda: '1696e7dc-514e-4c31-b713-8bb4e4442ea4'
    , Inteiro: '1696e7dc-514e-4c31-b713-8bb4e4442ea3'
    , Selecao: '1696e7dc-514e-4c31-b713-8bb4e4442ea2'
    , Check: '1696e7dc-514e-4c31-b713-8bb4e4442ea1'
    , Foto: '1696e7dc-514e-4c31-b713-8bb4e4442ea0'
  };



  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  acaoPage(qr: Qrcode) {

    /*if( this.util.isMobile()){
      alert('Mobile');
    } else{
      alert('Windows');
    }*/

    if (qr.tipo == "Redirecionar") {

      if (qr.subtipo == "Telefone")
        this.document.location.href = qr.conteudo;
      else
        this.document.location.href = this.util.validateLink(qr.conteudo);


    } else if (qr.tipo == "Texto Livre") {
      this.htmlConteudo = qr.conteudo;
    } else if (qr.tipo == "Arquivo Modo Exibição" || qr.tipo == "Arquivo Modo Download") {
      this.viewTipoFile(qr);
    } else if (qr.tipo == "Cartão de Visita") {
      console.log(qr);

      debugger
      this.ajustalinkFoto(qr);
      this.fundo = this.cartaoVisitaBuscarTipo("Fundo", qr);
      this.musicaFundo = this.cartaoVisitaBuscarTipo("Mp3 Fundo", qr);
      //this.fundo.valor = API.replace("api/", "") + this.fundo.valor.replace(/\\/g, "/");
      this.avatar = this.cartaoVisitaBuscarTipo("Foto", qr);
      //this.avatar.valor = API.replace("api/", "") + this.avatar.valor.replace(/\\/g, "/");
      this.removeItemMp3FundoArray('Mp3 Fundo');
      this.removeItemArray('Fundo');
      this.removeItemArray('Foto');
    }
    else if (qr.tipo == "Entrada de Dados") {
      console.log(qr);
      this.viewTipoEntradaDeDados(qr);
    }
  }

  ajustalinkFoto(qr: Qrcode) {
    for (let item of qr.cartaoVisita) {
      if (item.idTipoQrcodeCartaoVisita == "1596e7dc-514e-4c31-b713-8bb4e4442ea0"
        || item.idTipoQrcodeCartaoVisita == "1596e7dc-514e-4c31-b713-8bb4e4442ea1"
        || item.idTipoQrcodeCartaoVisita == "135a0f06-1f5b-4fb7-a11d-8df7b8fc337a"
        || item.tipoQrcodeCartaoVisita == "Mp3 Fundo")// foto
        item.valor = API.replace("api/", "") + item.valor.replace(/\\/g, "/");
    }
  }

  removeItemArray(key) {
    this.objQrcode.cartaoVisita.forEach((value, index) => {
      if (value.tipoQrcodeCartaoVisita == key && (value.ordem == 0 || value.Ordem))
        this.objQrcode.cartaoVisita.splice(index, 1);
    });
  }
  removeItemMp3FundoArray(key) {
    this.objQrcode.cartaoVisita.forEach((value, index) => {
      if (value.tipoQrcodeCartaoVisita == key )
        this.objQrcode.cartaoVisita.splice(index, 1);
    });
  }

  cartaoVisitaBuscarTipo(tipoCartaoVisita, qr) {

    if (qr.cartaoVisita != undefined) {
      let objEncontrado = qr.cartaoVisita.find(obj => obj.tipoQrcodeCartaoVisita === tipoCartaoVisita);
      return objEncontrado;
    }
    return null;
  }


  viewTipoFile(qr: Qrcode) {

    var url = "";
    let final = "";
    if (this.ps != '')
      final = "&Senha=" + this.ps;

    if (this.location == null) {
      if (qr.tipo == "Arquivo Modo Exibição")
        url = API + 'QrCode/' + this.param + "/File/?" + final;
      else
        url = API + 'QrCode/' + this.param + "/File/Download/?" + final;
    } else {
      if (qr.tipo == "Arquivo Modo Exibição")
        url = API + 'QrCode/' + this.param + "/File/?latitude=" + this.location.latitude + "&longitude" + this.location.longitude + final;
      else
        url = API + 'QrCode/' + this.param + "/File/Download/?latitude=" + this.location.latitude + "&longitude" + this.location.longitude + final;
    }

    this.document.location.href = this.linkArquivo = url;
    //this.linkArquivo = url;

  }

  redirectWithPassword(senha: string) {
    this.util.showLoading();
    var url = API + 'QrCode/' + this.param + "?Senha=" + senha;
    this.ajax.get(url)
      .subscribe((resp: Qrcode) => {
        debugger
        this.util.closeLoading();
        this.objQrcode = resp;
        this.ps = senha;
        this.acaoPage(resp);
      }, err => {
        this.util.closeLoading();
        debugger
        switch (err.status) {
          case 400: {
            this.util.toasterShowError("Atenção", err.error[0]);
            this.mensagemErro = err.error[0];
            break;
          }
          case 401: {
            this.util.toasterShowError("Atenção", "Não Autorizado");
            this.mensagemErro = "Não Autorizado";
            break;
          }

          default: {
            this.util.toasterShowError("Atenção", "Erro Desconhecido");
            this.mensagemErro = "Erro Desconhecido";
            break;
          }
        }

      });
  }



  getWithPassword() {
    Swal.fire({
      imageUrl: 'assets/images/logo/new/qrpig-1.png',
      footer: '<a href="'+REDIRECT_SITE+'">Conheça nossa plataforma e gere seu QRCODE </a>',
      text: 'Este QRCODE é protegido por senha, Entre com uma senha para acessá-lo!',
      input: 'password',
    }).then((result) => {
      if (result.value) {
        if (result.value != null) {
          this.redirectWithPassword(result.value);
        } else {
          this.util.toasterShowError("Atenção", "é obrigatório entrar com uma senha válida");
        }
      }
    });
  }

  getgetQrCode(location: GeoLocation) {
    if (this.param == undefined || this.param == "") {
      this.util.toasterShowError("Atenção", "Parametro inválido");
    } else {
      this.util.showLoading();
      var url = API + 'QrCode/' + this.param;

      if(this.param.toLowerCase() == '7a693a7a-127e-4528-be09-2df4e30a8d83'.toLocaleLowerCase()){
        this.addClass = 'fundoEjc';
        this.imgFundoFormularioPadrao='https://api.qrpig.com/qr_code_files/af4c0129-4dac-4dc8-af5d-5965ac3060f3/cartao_visita/b229ecb2-2cbd-4956-bd39-7013d65b90ac.jpg';
        this.imgLogoPadrao = this.imgFundoFormularioPadrao;
      }

      if (location == null) {

        this.ajax.get(url)
          .subscribe((resp: Qrcode) => {
            this.util.closeLoading();
            this.objQrcode = resp;
            this.urlRedirectEntradaDados = resp.urlRedirectEntradaDados;
            this.acaoPage(resp);
          }, err => {
            this.util.closeLoading();
            debugger
            switch (err.status) {
              case 400: {
                this.util.toasterShowError("Atenção", err.error[0]);
                this.mensagemErro = err.error[0];
                break;
              }
              case 401: {
                this.getWithPassword();
                break;
              }
              default: {
                this.util.toasterShowError("Atenção", "Erro Desconhecido");
                break;
              }
            }
          });

      } else {
        // quando o cara permitir eu envio a localizacao dele
        this.ajax.getGeoLocation(url, location)
          .subscribe((resp: Qrcode) => {
            this.util.closeLoading();
            this.objQrcode = resp;
            this.acaoPage(resp);
          }, err => {
            this.util.closeLoading();
            debugger
            switch (err.status) {
              case 400: {
                this.util.toasterShowError("Atenção", err.error[0]);
                this.mensagemErro = err.error[0];
                break;
              }
              case 401: {
                this.getWithPassword();
                break;
              }
              default: {
                this.util.toasterShowError("Atenção", "Erro Desconhecido");
                break;
              }
            }
          });
      }
    }
  }

  getQrcode() {
    let obj = new GeoLocation();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        obj.longitude = position.coords.longitude;
        obj.latitude = position.coords.latitude;
        this.location = obj;
        this.getgetQrCode(obj);
        console.log(obj);
      }, (erro) => {
        this.location = null;
        this.getgetQrCode(null);
      });
    } else {
      this.location = null;
      this.getgetQrCode(null);
    }




  }

  controlarClickCheckboxDinamico(event: any) {
    debugger;
    if (!event) {
      event = window.event; // Older versions of IE use
      // a global reference
      // and not an argument.
    };

    var el = (event.target || event.srcElement);

    var texto = (<HTMLInputElement>el).checked ? "Sim" : "Não";
    this.document.getElementById(el.getAttribute('id') + '_span').innerHTML = texto;
  }

  viewTipoEntradaDeDados(qr: Qrcode) {

    if (qr.templateDadosEntrada) {

      qr.templateDadosEntrada.map((dado) => {

        var input = { class: '', type: '', id: '', label: '', data: [], textMask: {} };

        var classeRequired = dado.obrigatorio ? 'required ' : '';
        var classeMascara = this.getClasseMascara(dado.idTipoEntrada);

        input.class = "form-control " + classeRequired + classeMascara;

        input.id = dado.id;
        input.label = dado.chave;

        //Seleção
        if (dado.idTipoEntrada == this.tiposEntrada.Selecao) {
          debugger;
          input.type = 'select';
          dado.valores.split('|').map(valor => {
            input.data.push({ 'value': valor });
          });
        }
        else if (dado.idTipoEntrada == this.tiposEntrada.Check) {
          input.type = 'check';
          input.class = input.class.replace("form-control", " ");
        }
        else if (dado.idTipoEntrada == this.tiposEntrada.Foto) {
          input.type = 'foto';
          input.class = input.class.replace("form-control", " ");
        }
        else {
          debugger
          input.type = 'text';
          let tipo = this.getClasseMascara(dado.idTipoEntrada);

          switch (tipo) {
            case "telefone": {
              input.textMask = { mask: maskTelefoneCelular };
              break;
            }
            case "cpf": {
              input.textMask = { mask: maskCPF };
              break;
            }
            case "moeda": {
              input.textMask = { mask: numberMaskReal };
              break;
            }

            case "inteiro": {
              input.textMask = { mask: numberMaskInt };
              break;
            }


          }

        }

        this.qrCodeEntradaFormTemplate.push(input);
      });

      let group = {}
      this.qrCodeEntradaFormTemplate.forEach(input_template => {
        group[input_template.label] = new FormControl('');
      })
      this.myFormGroup = new FormGroup(group);
    }
  }

  getClasseMascara(classe: string) {
    switch (classe) {
      case (this.tiposEntrada.TextoLivre): return "texto";
      case (this.tiposEntrada.Telefone): return "telefone";
      case (this.tiposEntrada.Cpf): return "cpf";
      case (this.tiposEntrada.Rg): return "rg";
      case (this.tiposEntrada.Email): return "email";
      case (this.tiposEntrada.Moeda): return "moeda";
      case (this.tiposEntrada.Inteiro): return "inteiro";
      default: return "texto";
    }

  }

  async fileBrowseHandler(file:File,name) {
    this.files.push({file,name});
  }

  salvarEntradaDados() {
  //ToDo ValorArquivo
  debugger;
    let valid = true;
    var dados =
    {
      IdQrCode: this.objQrcode.id,
      IdMetrica: this.objQrcode.metricId,
      DadosTemplateEntradaResposta: []
    };

    let dadosTemplateEntradaResposta = [];
    let hasFiles = false;
    let index = 0;

    document.querySelectorAll('#frmEntradaDados input[type=text],#frmEntradaDados input[type=checkbox],#frmEntradaDados select,input[type=file]').forEach(input => {

      let value = '';
      let fileValue = null;
      let type = input.getAttribute('type');

      if (type == 'text')
        value = (<HTMLInputElement>input).value
      else if (type == 'checkbox')
        value = (<HTMLInputElement>input).checked ? 'Sim' : "Não";
      else if (type == 'file')
      {
        try{
        hasFiles =true;
        fileValue = this.files[index].file;
        index++;
        value="Enviado";
        }catch(e){
          value='';
        }
      }else
        value = (<HTMLSelectElement>input).value;

      let isRequired = input.classList.contains('required');

      if (!value && isRequired) {
        valid = false;
        input.classList.add('invalid');
      } else {
        var id = input.getAttribute("id");
        var dado = this.montarInputTipoEntrada(id, isRequired, value,fileValue);
        dadosTemplateEntradaResposta.push(dado);
        input.classList.remove('invalid');
      }
    });


    if (valid) {
      this.mensagemCamposObrigatorios = '';
      dados.DadosTemplateEntradaResposta = dadosTemplateEntradaResposta;
      this.enviarDadosEntradaDados(dados,hasFiles);
    } else {
      this.mensagemCamposObrigatorios = "Favor preencher todos os campos obrigatórios!";
    }
  }

  montarInputTipoEntrada(idTemplateQrcodeEntrada: string, isRequired: boolean, value: string, fileValue:any = null) {
    var dados =
    {
      IdTemplateQrcodeEntrada: idTemplateQrcodeEntrada,
      Valor: value,
      Obrigatorio: isRequired,
      ValorArquivo:fileValue
    };
    return dados;
  }

  enviarDadosEntradaDados(dados,hasFiles) {
    var url = API + 'QrcodeEntradaTemplateResposta/' + this.objQrcode.id;

    this.util.showLoading();

    if(hasFiles)
      dados = this.util.jsonToFormData(dados);

    var result = hasFiles ?  this.ajax.postFile(`${url}/File`, dados) :this.ajax.post(url, dados);
    result.subscribe(() => {

        this.configurarRetornoSucessoSaveEntradaDeDados();
      }, err => {
        this.util.closeLoading();
        switch (err.status) {
          case 400: {
            this.util.toasterShowError("Atenção", "Erro Desconhecido");
            this.mensagemErro = err.error[0];
            break;
          }
          default: {
            this.util.toasterShowError("Atenção", "Erro Desconhecido");
            break;
          }
        }

      });
  }

  configurarRetornoSucessoSaveEntradaDeDados() {

    this.mensagemCamposObrigatorios = 'Dados enviados com sucesso, redirecionando em ' + this.contadorRedirect;
    this.isExibirBotaoSalvar = false;
    this.util.closeLoading();

    //Exibindo mensagem de contagem regressiva e efetuando o redirect
    var redirectInterval = setInterval(() => {
      this.contadorRedirect--;
      this.mensagemCamposObrigatorios = 'Dados enviados com sucesso, redirecionando em ' + this.contadorRedirect;

      if (this.contadorRedirect <= 0){

        this.mensagemCamposObrigatorios = 'Dados enviados com sucesso, redirecionando';

        if(this.urlRedirectEntradaDados )
          location.href = this.urlRedirectEntradaDados ;
        else
          location.href = REDIRECT_SITE;
        clearInterval(redirectInterval);
      }
    }, 1000);
  }
}

