<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>
<div class="col-12">
  <app-card [options]="false">
    <aw-wizard #wizard class="arc-wizard" navBarLayout="large-filled-symbols">
      <aw-wizard-step stepTitle="Fotos" [navigationSymbol]="{ symbol: '&#xe8e9;', fontFamily: 'feather' }"
        [style.font-weight]="900">
        <ng-template awWizardStepSymbol>
          <i class="fa fa-camera "></i>
        </ng-template>
        <div class="row">
          <div class="col-sm-6">
            <h5>Entre com a foto de Capa</h5>
            <hr>
            <app-card cardTitle="Capa do Cartão de Visitas" [options]="false">
              <file-upload [(ngModel)]="uploadedFilesCapa" [fileslimit]="1" accept="image/*" simple></file-upload>

            </app-card>
          </div>

          <div class="col-sm-6">
            <h5>Entre com a logo ou seu Avatar</h5>
            <hr>
            <app-card cardTitle="Logo ou Avatar" [options]="false">
              <file-upload [(ngModel)]="uploadedFilesAvatar" [fileslimit]="1" accept="image/*" simple></file-upload>

            </app-card>
          </div>
          <div class="col-sm-12 centered-content">
            <div class="btn-group mt-10">
              <button type="button" class="btn btn-primary " (click)="validarFotos()" >Próximo <i class="fa fa-chevron-right"></i>
              </button>
            </div>
          </div>
        </div>
      </aw-wizard-step>
      <aw-wizard-step stepTitle="Informaçoes" [navigationSymbol]="{ symbol: '&#xe88c;', fontFamily: 'feather' }"
        [style.font-weight]="900">
        <ng-template awWizardStepSymbol>
          <i class="fa fa-info"></i>
        </ng-template>

        <div class="row">
          <div class="col-sm-12">
            <h5>Entre com as informações </h5>
            <hr>
            <app-card cardTitle="Dados Pessoais ou Empresariais" [options]="false">
              <div class="form-group">
                <label for="exampleInputEmail1">Nome</label>
                <input type="text" class="form-control" [(ngModel)]="nome" name="nome" placeholder="">
              </div>
              <div class="form-group">
                <label for="exampleFormControlTextarea1">Descrição</label>
                <app-tinymce [(ngModel)]='descricao' #tinymce='tinymce' name="descricao"></app-tinymce>
                <!--<textarea class="form-control" [(ngModel)]="descricao" name="descricao" rows="3"></textarea>-->
              </div>
            </app-card>
            <div class="col-12 centered-content">
              <div class="btn-group mt-10">

                <button type="button" class="btn btn-secondary " awPreviousStep> <i class="fa fa-chevron-left"></i>
                  Voltar
                </button>
                <button type="button" class="btn btn-primary " (click)="validarInformacoes()">Próximo <i class="fa fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

      </aw-wizard-step>
      <aw-wizard-step stepTitle="Contatos" [navigationSymbol]="{ symbol: '&#xe840;', fontFamily: 'feather' }">
        <ng-template awWizardStepSymbol>
          <i class="fas fas fa-address-book"></i>
        </ng-template>
        <div class="row">
          <div class="col-sm-12">
            <h5>Escolha seus Contatos</h5>
            <hr>
            <!--<app-card [hidHeader]="true" cardClass="text-center">-->
            <div class="row">


              <div class="col-xl-4 col-md-6">
                <app-card [hidHeader]="true">
                  <h6 class="mb-0 float-right"></h6>
                  <span class="d-block mb-1"></span>
                  <div class="row align-items-center justify-content-center">
                    <div class="col-auto">
                      <h2 class="text-muted  m-0"><i class="fa fa-mobile text-info mr-2"></i> Celular </h2>
                    </div>
                    <div class="col text-right">
                      <div class="form-group ">
                        <!--<div class="switch switch-primary d-inline">
                            <input type="checkbox" id="switch-a-1" checked="">
                            <label for="switch-a-1" class="cr"></label>
                          </div>-->

                        <label class="  control-label text-muted text-muted">Ordem Apresent.</label>
                        <select class="form-control" [(ngModel)]='linkTelefone.Ordem'>
                          <option value="{{i}}" *ngFor="let i of arrayOrdem">{{i}}</option>
                        </select>
                      </div>
                    </div>

                  </div>
                  <div class="row">
                    <div class="col-12">
                      <br>
                      <div class="form-group ">
                        <input type="text" class="form-control" [(ngModel)]="linkTelefone.Valor" name="linkTelefone"
                          [textMask]="{mask: maskTeleArea}" placeholder="(99) 9 9999-9999">
                      </div>
                      <!--<button type="submit" class="btn btn-primary end">Salvar</button>-->

                    </div>
                  </div>
                </app-card>

              </div>
              <div class="col-xl-4 col-md-6">
                <app-card [hidHeader]="true">
                  <h6 class="mb-0 float-right"></h6>
                  <span class="d-block mb-1"></span>
                  <div class="row align-items-center justify-content-center">
                    <div class="col-auto">
                      <h2 class="text-muted  m-0"><i class="feather icon-phone text-info mr-2"></i> Telefone </h2>
                    </div>
                    <div class="col text-right">
                      <div class="form-group ">
                        <!--<div class="switch switch-primary d-inline">
                            <input type="checkbox" id="switch-a-1" checked="">
                            <label for="switch-a-1" class="cr"></label>
                          </div>-->

                        <label class="  control-label text-muted text-muted">Ordem Apresent.</label>
                        <select class="form-control" [(ngModel)]='linkTelefoneFixo.Ordem'>
                          <option value="{{i}}" *ngFor="let i of arrayOrdem">{{i}}</option>
                        </select>
                      </div>
                    </div>

                  </div>
                  <div class="row">
                    <div class="col-12">
                      <br>
                      <div class="form-group ">
                        <input type="text" class="form-control" [(ngModel)]="linkTelefoneFixo.Valor"
                          name="linkTelefoneFixo" [textMask]="{mask: maskTeleAreaFixo}" placeholder="(99) 9999-9999">
                      </div>
                      <!--<button type="submit" class="btn btn-primary end">Salvar</button>-->

                    </div>
                  </div>
                </app-card>

              </div>

              <div class="col-xl-4 col-md-6">
                <app-card [hidHeader]="true">
                  <h6 class="mb-0 float-right"></h6>
                  <span class="d-block mb-1"></span>
                  <div class="row align-items-center justify-content-center">
                    <div class="col-auto">
                      <h2 class="text-muted  m-0"><i class="fab fa-whatsapp text-info mr-2 text-info mr-2"></i> Whatsapp
                      </h2>
                    </div>
                    <div class="col text-right">
                      <div class="form-group ">
                        <label class="  control-label text-muted">Ordem Apresent.</label>
                        <select class="form-control" [(ngModel)]='linkWhatsapp.Ordem'>
                          <option value="{{i}}" *ngFor="let i of arrayOrdem">{{i}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <br>
                      <div class="form-group ">
                        <input type="text" class="form-control" [(ngModel)]="linkWhatsapp.Valor" name="linkWhatsapp"
                          [textMask]="{mask: maskTeleArea}" placeholder="(99) 9 9999-9999">
                      </div>
                    </div>
                  </div>
                </app-card>
              </div>


              <div class="col-xl-6 col-md-6">
                <app-card [hidHeader]="true">
                  <h6 class="mb-0 float-right"></h6>
                  <span class="d-block mb-1"></span>
                  <div class="row align-items-center justify-content-center">
                    <div class="col-auto">
                      <h2 class="text-muted  m-0"><i class="fas fa-globe text-info mr-2"></i> Site </h2>
                    </div>
                    <div class="col text-right">
                      <div class="form-group ">
                        <label class="  control-label text-muted">Ordem Apresent.</label>
                        <select class="form-control" [(ngModel)]='linkSite.Ordem'>
                          <option value="{{i}}" *ngFor="let i of arrayOrdem">{{i}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <br>
                      <div class="form-group">
                        <label for="exampleInputEmail1"> Link do site</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroupPrepend">https://</span>
                          </div>
                          <input type="text" class="form-control" [(ngModel)]="linkSite.Valor" name="linkSite"
                          placeholder="Entre com o link do site" >
                        </div>
                      </div>
                    </div>
                  </div>
                </app-card>
              </div>

              <div class="col-xl-6 col-md-6">
                <app-card [hidHeader]="true">
                  <h6 class="mb-0 float-right"></h6>
                  <span class="d-block mb-1"></span>
                  <div class="row align-items-center justify-content-center">
                    <div class="col-auto">
                      <h2 class="text-muted  m-0"><i class="fab fa-facebook-f text-info mr-2"></i> Facebook </h2>
                    </div>
                    <div class="col text-right">
                      <div class="form-group ">
                        <label class="  control-label text-muted">Ordem Apresent.</label>
                        <select class="form-control" [(ngModel)]='linkFacebook.Ordem'>
                          <option value="{{i}}" *ngFor="let i of arrayOrdem">{{i}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <br>
                      <div class="form-group">
                        <label for="exampleInputEmail1"> Usuário do Facebook</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroupPrepend">https://facebook.com/</span>
                          </div>
                          <input type="text" class="form-control" placeholder="" [(ngModel)]="linkFacebook.Valor" name="linkFacebook"
                          placeholder="Entre com o usuario do Facebook">
                        </div>
                      </div>
                    </div>
                  </div>
                </app-card>
              </div>

              <div class="col-xl-6 col-md-6">
                <app-card [hidHeader]="true">
                  <h6 class="mb-0 float-right"></h6>
                  <span class="d-block mb-1"></span>
                  <div class="row align-items-center justify-content-center">
                    <div class="col-auto">
                      <h2 class="text-muted  m-0"><i class="fab fa-twitter text-info mr-2"></i> Twitter </h2>
                    </div>
                    <div class="col text-right">
                      <div class="form-group ">
                        <label class="  control-label text-muted">Ordem Apresent.</label>
                        <select class="form-control" [(ngModel)]='linkTwitter.Ordem'>
                          <option value="{{i}}" *ngFor="let i of arrayOrdem">{{i}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <br>
                      <div class="form-group">
                        <label for="exampleInputEmail1"> Usuário do Twitter</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroupPrepend">https://twitter.com/</span>
                          </div>
                          <input type="text" class="form-control" placeholder="" [(ngModel)]="linkTwitter.Valor" name="linkTwitter"
                          placeholder="Entre com o usuário do Twitter">
                        </div>
                      </div>
                    </div>
                  </div>
                </app-card>
              </div>

              <div class="col-xl-6 col-md-6">
                <app-card [hidHeader]="true">
                  <h6 class="mb-0 float-right"></h6>
                  <span class="d-block mb-1"></span>
                  <div class="row align-items-center justify-content-center">
                    <div class="col-auto">
                      <h2 class="text-muted  m-0"><i class="fab fa-youtube text-info mr-2"></i> Youtube </h2>
                    </div>
                    <div class="col text-right">
                      <div class="form-group ">
                        <label class="  control-label text-muted">Ordem Apresent.</label>
                        <select class="form-control" [(ngModel)]='linkYoutube.Ordem'>
                          <option value="{{i}}" *ngFor="let i of arrayOrdem">{{i}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <br>
                      <div class="form-group">
                        <label for="exampleInputEmail1"> Usuário do Youtube</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroupPrepend">https://www.youtube.com/</span>
                          </div>
                          <input type="text" class="form-control" placeholder="" [(ngModel)]="linkYoutube.Valor" name="linkYoutube"
                          placeholder="Entre com o usuário do Youtube">
                        </div>
                      </div>
                    </div>
                  </div>
                </app-card>
              </div>

              <div class="col-xl-6 col-md-6">
                <app-card [hidHeader]="true">
                  <h6 class="mb-0 float-right"></h6>
                  <span class="d-block mb-1"></span>
                  <div class="row align-items-center justify-content-center">
                    <div class="col-auto">
                      <h2 class="text-muted  m-0"><i class="fab fa-linkedin-in text-info mr-2"></i> Linkedin </h2>
                    </div>
                    <div class="col text-right">
                      <div class="form-group ">
                        <label class="  control-label text-muted">Ordem Apresent.</label>
                        <select class="form-control" [(ngModel)]='linkLinkedin.Ordem'>
                          <option value="{{i}}" *ngFor="let i of arrayOrdem">{{i}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <br>
                      <div class="form-group">
                        <label for="exampleInputEmail1"> Usuário do Linkedin</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroupPrepend">https://linkedin.com/in/</span>
                          </div>
                          <input type="text" class="form-control" placeholder="" [(ngModel)]="linkLinkedin.Valor" name="linkLinkedin"
                          placeholder="Entre com o usuário do linkedin">
                        </div>
                      </div>
                    </div>
                  </div>
                </app-card>
              </div>

              <div class="col-xl-6 col-md-6">
                <app-card [hidHeader]="true">
                  <h6 class="mb-0 float-right"></h6>
                  <span class="d-block mb-1"></span>
                  <div class="row align-items-center justify-content-center">
                    <div class="col-auto">
                      <h2 class="text-muted  m-0"><i class="fab fa-instagram text-info mr-2"></i> Instagram </h2>
                    </div>
                    <div class="col text-right">
                      <div class="form-group ">
                        <label class="  control-label text-muted">Ordem Apresent.</label>
                        <select class="form-control" [(ngModel)]='linkInstagram.Ordem'>
                          <option value="{{i}}" *ngFor="let i of arrayOrdem">{{i}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <br>
                      <div class="form-group">
                        <label for="exampleInputEmail1"> Usuário do instagram</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroupPrepend">@</span>
                          </div>
                          <input type="text" class="form-control" placeholder="" [(ngModel)]="linkInstagram.Valor" name="linkInstagram"
                          placeholder="Entre com o usuário do Instagram">
                        </div>
                      </div>

                    </div>
                  </div>


                </app-card>
              </div>


              <div class="col-xl-6 col-md-6">
                <app-card [hidHeader]="true">
                  <h6 class="mb-0 float-right"></h6>
                  <span class="d-block mb-1"></span>
                  <div class="row align-items-center justify-content-center">
                    <div class="col-auto">
                      <h2 class="text-muted  m-0"><i class="fab fa-telegram text-info mr-2"></i> Telegram </h2>
                    </div>
                    <div class="col text-right">
                      <div class="form-group ">
                        <label class="  control-label text-muted">Ordem Apresent.</label>
                        <select class="form-control" [(ngModel)]='linkTelegram.Ordem'>
                          <option value="{{i}}" *ngFor="let i of arrayOrdem">{{i}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <br>
                      <div class="form-group">
                        <label for="exampleInputEmail1"> Username</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroupPrepend">https://t.me/</span>
                          </div>
                          <input type="text" class="form-control" placeholder="" [(ngModel)]="linkTelegram.Valor" name="linkTelegram"
                          placeholder="Entre com o usuário do Telegram" id="telegram" value="telegram" >
                        </div>
                      </div>


                    </div>
                  </div>
                </app-card>
              </div>


              <div class="col-xl-12 col-md-12">
                <app-card [hidHeader]="true">
                  <h6 class="mb-0 float-right"></h6>
                  <span class="d-block mb-1"></span>
                  <div class="row align-items-center justify-content-center">
                    <div class="col-auto">
                      <h2 class="text-muted  m-0"><i class="feather icon-map text-info mr-2"></i> Mapa </h2>
                    </div>
                    <div class="col text-right col-xl-6 col-md-6">
                      <div class="form-group ">
                        <label class="  control-label text-muted">Ordem Apresent.</label>
                        <select class="form-control" [(ngModel)]='linkMapa.Ordem'>
                          <option value="{{i}}" *ngFor="let i of arrayOrdem">{{i}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <br>





                        <div class="row">
                          <div class="form-group col-md-8 col-xl-8 col-sm-12">
                            <label>Rua</label>
                            <input type="text" class="form-control" [(ngModel)]="rua" name="rua">
                          </div>
                          <div class="form-group col-md-4 col-xl-4 col-sm-12">
                            <label>Número</label>
                            <input type="text" class="form-control" [(ngModel)]="numero" name="numero">
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-md-4 col-xl-4 col-sm-12">
                            <label>Bairro </label>
                            <input type="text" class="form-control" [(ngModel)]="bairro" name="bairro">
                          </div>
                          <div class="form-group col-md-4 col-xl-4 col-sm-12">
                            <label>Cidade</label>
                            <input type="text" class="form-control" [(ngModel)]="cidade" name="cidade">
                          </div>
                          <div class="form-group col-md-4 col-xl-4 col-sm-12">
                            <label>Estado</label>
                            <input type="text" class="form-control" [(ngModel)]="estado" name="estado">
                          </div>
                        </div>





                    </div>
                  </div>


                </app-card>
              </div>


            </div>
          </div>

        </div>
        <div class="col-sm-12 centered-content">
          <div class="btn-group mt-10">
            <button type="button" class="btn btn-secondary btn-lg" awPreviousStep> <i class="fa fa-chevron-left"></i>
              Voltar
            </button>
            <button type="button" class="btn btn-success btn-lg" (click)="salvarCartao()"> Salvar <i class="fa fa-check-circle"></i>
            </button>
          </div>
        </div>
      </aw-wizard-step>
    </aw-wizard>
  </app-card>
</div>
