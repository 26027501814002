import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SecretToken } from 'src/environments/environment';
import { GeoLocation } from '../model/geoLocation';


export const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'SecretToken': SecretToken,
    'Access-Control-Allow-Credentials': 'false',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE, PUT, OPTIONS',
    'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
  })
};

@Injectable({
  providedIn: 'root'
})



export class AjaxService {

  public headers: any;
  private options: any;
  private userLang: string;


  constructor(
    private http: HttpClient
  ) {
    this.userLang = this.GetLanguage();

  }

  GetLanguage() {
    var lang = window.navigator.language;

    if (lang) {
      lang = lang.toLowerCase();
      return lang.startsWith('pt')
        ? 'pt-br'
        : 'en-us'
    }
    else return 'pt-br';

  }

  post(url: string, data: any): any {
    this.headers = HTTP_OPTIONS.headers.set('Content-Language',  this.userLang);
    this.options = {
      headers: this.headers
    };

    return this.http.post<any>(url, data, this.options);
  }

  postFile(url: string, data: any): any {
    
    this.options = {
      headers: new HttpHeaders({'SecretToken': SecretToken})
    };

    return this.http.post<any>(url, data, this.options);
  }

  putFile(url: string, data: any): any {

    this.options = {
      headers: new HttpHeaders({'SecretToken': SecretToken})
    };

    return this.http.put<any>(url, data, this.options);
  }


  put(url: string, data: any): any {
    this.headers = HTTP_OPTIONS.headers.set('Content-Language',  this.userLang);

    this.options = {
      headers: this.headers
    };

    return this.http.put<any>(url, data, this.options);
  }

  get(url): any {

    this.headers = HTTP_OPTIONS.headers.set('Content-Language',  this.userLang);

    this.options = {
      headers: this.headers
    };
    
    return this.http.get<any>(url, this.options);
  }

  getGeoLocation(url, location : GeoLocation): any {

    this.headers = HTTP_OPTIONS.headers
    .set('Content-Language',  this.userLang)
    .set('x-latitude',location.latitude.toString())
    .set('x-longitude',location.longitude.toString());

    this.options = {
      headers: this.headers
    };
    
    return this.http.get<any>(url, this.options);
  }
}
