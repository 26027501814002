<div class="row mb-n4">
  <!--<div class="col-xl-4 col-md-6">
      <h6 class="text-center mb-3">Simple</h6>
      <app-card [customHeader]="true" headerClass="border-0 p-2 pb-0" [options]="false" cardClass="user-card user-card-1" blockClass="pt-0 users-contact">
        <div class="app-card-header">
          <div class="cover-img-block">
            <img src="assets/images/widget/slider5.jpg" alt="" class="img-fluid">
          </div>
        </div>
        <div class="user-about-block text-center">
          <div class="row align-items-end">
            <div class="col"></div>
            <div class="col">
              <div class="position-relative d-inline-block">
                <img class="img-radius img-fluid wid-80" src="assets/images/user/avatar-2.jpg" alt="User image">
              </div>
            </div>
            <div class="col"></div>
          </div>
        </div>
        <div class="text-center">
          <h6 class="mb-1 mt-3">Josephin Doe</h6>
          <p class="mb-3 text-muted">UI/UX Designer</p>
          <p class="mb-1">Lorem Ipsum is simply dummy text</p>
          <p class="mb-0">been the industry's standard</p>
        </div>
        <hr class="wid-80 b-wid-3 my-4">
        <div class="row text-center">
          <div class="col">
            <h6 class="mb-1">37</h6>
            <p class="mb-0">Mails</p>
          </div>
          <div class="col">
            <h6 class="mb-1">2749</h6>
            <p class="mb-0">Followers</p>
          </div>
          <div class="col">
            <h6 class="mb-1">678</h6>
            <p class="mb-0">Following</p>
          </div>
        </div>
      </app-card>
    </div>-->
  <div class="col-xl-4 col-md-6">

    <app-card [customHeader]="true" headerClass="border-0 p-2 pb-0" [options]="false"
      cardClass="user-card user-card-2 support-bar1 shape-right" blockClass="pt-0 users-contact" [isCardFooter]="false">
      <div class="app-card-header">
        <div class="cover-img-block">
          <img src="assets/images/widget/slider5.jpg" alt="" class="img-fluid">
        </div>
      </div>
      <div class="user-about-block text-center">
        <div class="row align-items-center">
          <div class="col">
            <div class="row align-items-center">
              <div class="col-auto col pr-0">
                <img class="img-radius img-fluid wid-80" src="assets/images/user/avatar-3.jpg" alt="User image">
              </div>
              <div class="col">
                <h6 class="mb-1">Sara Soudein</h6>
                <p class="mb-0">UI/UX Designer</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <p class="mb-1">Lorem Ipsum is simply dummy text</p>
        <p class="mb-0">been the industry's standard</p>

      </div>

      <!--<ul class="list-inline f-20 mt-3 mb-0">
            <li class="list-inline-item"><a href="javascript:" class="text-facebook"><i class="fab fa-facebook-f"></i></a></li>
            <li class="list-inline-item"><a href="javascript:" class="text-twitter"><i class="fab fa-twitter"></i></a></li>
            <li class="list-inline-item"><a href="javascript:" class="text-dribbble"><i class="fab fa-dribbble"></i></a></li>
            <li class="list-inline-item"><a href="javascript:" class="text-pinterest"><i class="fab fa-pinterest"></i></a></li>
            <li class="list-inline-item"><a href="javascript:" class="text-youtube"><i class="fab fa-youtube"></i></a></li>
            <li class="list-inline-item"><a href="javascript:" class="text-googleplus"><i class="fab fa-google-plus-g"></i></a></li>
            <li class="list-inline-item"><a href="javascript:" class="text-linkedin"><i class="fab fa-linkedin-in"></i></a></li>
          </ul>-->
      <br>
      <!--<div class="row justify-content-center user-social-link">
            <div class="col-auto"><a href="#!"><i class="fab fa-facebook text-facebook"></i></a></div>
            <div class="col-auto"><a href="#!"><i class="fab fa-twitter text-twitter"></i></a></div>
            <div class="col-auto"><a href="#!"><i class="fab fa-dribbble text-dribbble"></i></a></div>
          </div>-->
      <div class="app-card-footer">
        <div class="row text-center">
          <div class="col">

            <i class="fab fa-facebook"></i>


          </div>

          <div class="col">
            <h6 class="mb-1">678</h6>
            <p class="mb-0">Following</p>
          </div>
        </div>
      </div>
    </app-card>
    <div class="col-md-12 col-xl-12">
      <app-card [hidHeader]="true" cardClass="bg-dark notification-card">
        <div class="row align-items-center">
          <div class="col-4 notify-icon"><i class="feather icon-phone"></i></div>
          <div class="col-8 notify-cont">
            <p>Telefone</p>
          </div>
        </div>
      </app-card>
    </div>
    <div class="col-md-12 col-xl-12">
      <app-card [hidHeader]="true" cardClass="bg-dark notification-card">
        <div class="row align-items-center">
          <div class="col-4 notify-icon"><i class="fab fa-facebook"></i></div>
          <div class="col-8 notify-cont">
            <p>Facebook</p>
          </div>
        </div>
      </app-card>
    </div>
    <div class="col-md-12 col-xl-12">
      <app-card [hidHeader]="true" cardClass="bg-dark notification-card">
        <div class="row align-items-center">
          <div class="col-4 notify-icon"><i class="fab fa-youtube"></i></div>
          <div class="col-8 notify-cont">
            <p>Youtube</p>
          </div>
        </div>
      </app-card>
    </div>
    <div class="col-md-12 col-xl-12">
      <app-card [hidHeader]="true" cardClass="bg-dark notification-card">
        <div class="row align-items-center">
          <div class="col-4 notify-icon"><i class="fab fa-twitter"></i></div>
          <div class="col-8 notify-cont">
            <p>Twitter</p>
          </div>
        </div>
      </app-card>
    </div>
    <div class="col-md-12 col-xl-12">
      <app-card [hidHeader]="true" cardClass="bg-dark notification-card">
        <div class="row align-items-center">
          <div class="col-4 notify-icon"><i class="fab fa-linkedin-in"></i></div>
          <div class="col-8 notify-cont">
            <p>Linkedin</p>
          </div>
        </div>
      </app-card>
    </div>
  </div>


  <!--<div class="col-md-6 col-lg-4">
        <app-card [hidHeader]="true" blockClass="text-center">
          <i class="fab fa-twitter text-c-green d-block f-40"></i>
          <h4 class="m-t-20"><span class="text-c-blgreenue">+40</span> Followers</h4>
        </app-card>
      </div>-->

</div>
