import { formatDate } from '@angular/common';
import { AuthService } from 'src/app/service/auth.service';
import { Component, OnInit } from '@angular/core';
import { UtilService } from 'src/app/service/util.service';
import { AjaxService } from 'src/app/service/ajax.service';
import { Router } from '@angular/router';
import { ResponseUser } from 'src/app/model/User.model';
import { API, API_QRCODE, TIPOS } from 'src/environments/environment';
import QrCodeWithLogo from "qrcode-with-logos";
import { Qrcode } from 'src/app/model/Qrcode';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  user: ResponseUser;
  arrayQrcode: [Qrcode];
  query: string;
  metricasDetalhadas: any;
  metricasTipoEntrada: any;
  metricasResumidas: any;
  dtResponsiveOptions: any = {};

  public TIPO_QR_CODE = { CartaoVisita : '70b918a0-4b21-4ddd-88f3-9ef23408fb12'}

  constructor(
    private router: Router,
    private ajax: AjaxService,
    public util: UtilService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.user = this.auth.getUser();
    this.buscarQrcode();
  }



  onKeydownEvent(event: KeyboardEvent): void {
    //console.log(event);
    if (event.key === "Backspace") {
      //console.log("achou backspace");
      this.atualizaQrcode();
    }
  }

  editar(qr: Qrcode) {
  if(qr.idTipoQrCode == this.TIPO_QR_CODE.CartaoVisita){
      this.router.navigate(['/cadastrar-cartao-visita', qr.id]);
   }
   else{
    this.router.navigate(['/edit-qrcode', qr.id]);
   }
}

  isCanvas(obj: HTMLCanvasElement | HTMLElement): obj is HTMLCanvasElement {
    return obj.tagName === 'CANVAS';
  } intervaloMax

  downloadQrcode(qr: Qrcode) {
    var canvas = document.getElementById(qr.id);
    let url = API_QRCODE + qr.id;

    if (this.isCanvas(canvas)) {
      let qrcode = new QrCodeWithLogo({
        content: url,
        width: 500,
        download: true,
        downloadName: qr.nome.replace(/\./g, '')
      }).toImage();
    }
  }

  gerarQrcode(idQrcode, url) {

    var canvas = document.getElementById(idQrcode);
    if (this.isCanvas(canvas)) {

      let qrcode = new QrCodeWithLogo({
        canvas: canvas,
        content: url,
        width: 100,
        download: false,
      }).toImage();
    }

  }

  buscarQuantidadeAcessos(item: Qrcode) {
    let urlResumo = API + "QrCode/" + item.id + "/MetricasResumidas";
    this.ajax.get(urlResumo)
      .subscribe(resp => {
        item.quantidadeAcessos = resp.quantidadeAcessos;
      }, err => {

      });
  }

  atualizaQrcode() {
    debugger
    for (let item of this.arrayQrcode) {
      let url = API_QRCODE + item.id;
      this.gerarQrcode(item.id, url);
      this.buscarQuantidadeAcessos(item);
      this.util.closeLoading();
    }
  }

  async modalConfiguracao(qr: Qrcode) {
    this.router.navigate(['/config-qrcode', qr.id]);
  }


  pageEstatisticas(qr: Qrcode) {
    this.router.navigate(['/estatistica', qr.id]);
  }

  mostrarModal(qr: Qrcode) {
    debugger;
    let message = ' ' +
      '<div class="modal-body"> ' +
      '		<h4 class="mb-2">URL</h4> ' +
      '		<div class="form-group mb-4"> ' +
      '			<input type="text" value="' + API_QRCODE + qr.id + '" class="form-control pl-2" readonly=""> ' +
      '		</div>' +
      '   <div>' +
      '<div class="table-responsive" style="width:100%;">														      ' +
      '<fieldset><legend>Métricas de Acesso</legend>														         ' +
      '<table class="table">' +
      '    <thead">                                                                       ' +
      '    <tr>                                                                          ' +
      '      <th>Data</th>                                                               ' +
      '      <th>IP</th>                                                                 ' +
      '      <th>Browser</th>                                                            ' +
      '    </tr>                                                                         ' +
      '    </thead>                                                                      ' +
      '    <tbody >                                                                      ';
    for (var item of this.metricasDetalhadas) {
      console.log(item);
      message += '<tr><td>' + this.util.formatDateString(item.dataLeitura) + '</td>          ' +
        '      <td>' + item.ip + '</td>                                               ' +
        '      <td>' + item.browser + '</td>                                           ' +
        '    </tr>                                                                    ';
    }
    message +=
      '    </tbody>                                                                      ' +
      '  </table>                                                                        ' +
      '</fieldset>													         ';

    if ((this.metricasTipoEntrada) && (this.metricasTipoEntrada.dados) && (this.metricasTipoEntrada.dados.length > 0)) {

      message += `<a class='btn btn-info' target='_blank' href='${API}QrCode/${qr.id}/MetricasDetalhadasEntradaExcel'>Exportar</a><br /><fieldset><legend>Métricas de Entrada</legend>

  <table class="table">
  <thead">
  <tr>
    <th>Quantidade de Scans</th>
    <th>Quantidade de Respostas</th>
    <th>Scans sem Respotas</th>
  </tr>
 </thead>
  <tbody>
  <tr>
  <td>${this.metricasTipoEntrada.quantidadeScans}</td>
  <td>${this.metricasTipoEntrada.quantidadeRespostas}</td>
  <td>${this.metricasTipoEntrada.scansSemResposta}</td>
  </tr>
</tbody>
</table> `;


      if (this.metricasTipoEntrada.dados) {
        var idGrupo = this.metricasTipoEntrada.dados[0].agrupador;
        var tabela = '';
        var tempoMedioResposta = '';
        var lidoEm = '';
        var respondidoEm = '';

        for (var item of this.metricasTipoEntrada.dados) {

          if (idGrupo != item.agrupador) {
            message += this.getTabelaEntradaRespostaDeDados(tabela, tempoMedioResposta, lidoEm, respondidoEm);
            tabela = '';
            idGrupo = item.agrupador;
          }

          tempoMedioResposta = item.tempoParaResponder;
          lidoEm = this.util.formatDateString(item.dataLeituraQrCode);
          respondidoEm = this.util.formatDateString(item.dataResposta);
debugger;
          tabela += `<tr>
                <td>${item.titulo}</td>
                <td>${item.resposta && item.resposta.indexOf('qr_code_files') >=0 ? this.ajustalinkFoto(item.resposta) : item.resposta }</td>
                <td>${item.ipLeitura}</td>
                <td>${item.ipResposta}</td>
                <td>${item.localizacao}</td>
             </tr>`;
        }

        if (this.metricasTipoEntrada.dados) {
          //adição da última tabela
          message += this.getTabelaEntradaRespostaDeDados(tabela, tempoMedioResposta, lidoEm, respondidoEm);
        }
        message += '</fieldset>';
      }
    }

    message += '</div>    ' +
      '	</div>	';

    Swal.fire({
      title: 'Estatísticas',
      type: 'warning',
      html: message,
      width: '95%'
    }).then((result) => {

    });
  }

  ajustalinkFoto(valor) {
    let url = API.replace("api/", "") +valor.replace(/\\/g, "/");
    return `<a href='${url}' target='_blank' style='cursor:pointer;'><img width='100' src='${url}'/></a>`;
  }

  getTabelaEntradaRespostaDeDados(tabela: string, tempoMedioResposta: string, lidoEm: string, respondidoEm: string) {
    var message = ` <fieldset><legend>Lido em ${lidoEm} - Respondido em ${respondidoEm}</legend>
    <table class="table">
    <thead">
    <tr>
      <th>Campo</th>
      <th>Resposta</th>
      <th>Ip Leitura</th>
      <th>Ip Resposta</th>
      <th>Localização</th>
    </tr>
   </thead>
    <tbody>
    ${tabela}
    <tr><td colspan='5'>Tempo que o usuário levou para responder:${tempoMedioResposta}</td></tr>
  </tbody>
  </table></fieldset>        							         ` ;

    return message;
  }

  verEstatisticas(qr: Qrcode) {
    if (qr.idTipoQrCode == TIPOS.EntradaDeDados) {
      this.verEstatisticasQrEntrada(qr, this.verEstatisticasGerais);
    }
    else
      this.verEstatisticasGerais(qr);
  }

  verEstatisticasGerais(qr: Qrcode) {
    this.util.showLoading();
    let urlDetalhe = API + "QrCode/" + qr.id + "/MetricasDetalhadas";
    this.ajax.get(urlDetalhe)
      .subscribe(resp => {
        this.metricasDetalhadas = resp;
        this.mostrarModal(qr);
        this.util.closeLoading();

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao buscar Dados");
      });
  }

  verEstatisticasQrEntrada(qr: Qrcode, successFunc: any) {

    var context = this;
    if (qr.idTipoQrCode == TIPOS.EntradaDeDados) {
      this.util.showLoading();
      let urlDetalhe = API + "QrCode/" + qr.id + "/MetricasDetalhadasEntrada";
      this.ajax.get(urlDetalhe)
        .subscribe(resp => {
          this.metricasTipoEntrada = resp;
          this.util.closeLoading();

          successFunc.call(context, qr);
        }, err => {
          debugger
          this.util.closeLoading();
          this.util.toasterShowError("Atenção", "Erro ao buscar Dados");
        });
    }
  }

  buscarQrcode() {
    this.util.showLoading();
    var url = API + 'User/' + this.user.user.id + '/QrCode';
    this.ajax.get(url)
      .subscribe(resp => {

        console.log(resp);
        this.arrayQrcode = resp;

        setTimeout(() => {
          this.atualizaQrcode();
        }, 500);


        if (this.arrayQrcode.length <= 0)
          this.util.closeLoading();

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao buscar Dados");
      });
  }

}
