import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DadosTemplateEntrada } from 'src/app/model/DadosTemplateEntrada';
import { ResponseUser } from 'src/app/model/User.model';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API, maskCPF, maskTelefoneCelular, numberMaskInt, numberMaskReal } from 'src/environments/environment';

@Component({
  selector: 'app-cadastro-tipo-entrada',
  templateUrl: './cadastro-tipo-entrada.component.html',
  styleUrls: ['./cadastro-tipo-entrada.component.scss']
})
export class CadastroTipoEntradaComponent implements OnInit {

  maskCPF = maskCPF;
  maskTelefoneCelular = maskTelefoneCelular;
  numberMaskInt = numberMaskInt;
  numberMaskReal = numberMaskReal;

  user: ResponseUser;
  arrayTipos: any;
  campoLabel: string;
  arraySelectOpcao: string[];
  tipoCampoSelecionado: string;
  ehObrigatorio: boolean;

  nomeQrcode: string;
  descricaoQrcode: string;
  redirecionarQrcode:string;

  arrayFormulario: DadosTemplateEntrada[]

  constructor(
    private router: Router,
    private ajax: AjaxService,
    public util: UtilService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.user = this.auth.getUser();
    this.buscarTipos();
    this.arrayFormulario = [];
    this.nomeQrcode = "";
    this.descricaoQrcode = "";
    this.ehObrigatorio = false;
    this.redirecionarQrcode = "";
  }

  validaAdd() {
    if (this.campoLabel == undefined || this.campoLabel == "") {
      this.util.toasterShowError("Atenção", "Entre com a Descricao do Campo");
      return false;
    }

    if (this.tipoCampoSelecionado == "1696e7dc-514e-4c31-b713-8bb4e4442ea2") { // select
      if (this.arraySelectOpcao == undefined || this.arraySelectOpcao.length == 0) {
        this.util.toasterShowError("Atenção", "Digite com ao menos uma opção");
        return false;
      }
    }

    return true;
  }

  ajustaValorSelecao() {
    debugger
    let valores = [];
    for (let item of this.arraySelectOpcao) {
      valores.push(item['value']);
    }
    return valores;
  }

  addItem() {
    if (this.validaAdd()) {
      let dados = new DadosTemplateEntrada();
      dados.chave = this.campoLabel;
      dados.idTipoEntrada = this.tipoCampoSelecionado;
      dados.ordem = this.arrayFormulario.length + 1;
      dados.obrigatorio = this.ehObrigatorio;
      let tipo = this.arrayTipos.find(c => c.id == this.tipoCampoSelecionado);
      dados.nomeCampo = tipo.descricao;

      if (tipo.id == "1696e7dc-514e-4c31-b713-8bb4e4442ea2") {// selecao de dados
        dados.valores = this.ajustaValorSelecao();
      }
      this.arrayFormulario.push(dados);
      this.limparItem();
    }

  }

  limparItem() {
    this.campoLabel = "";
    this.arraySelectOpcao = [];
    this.ehObrigatorio = false;
  }

  deleteItem(index) {
    this.arrayFormulario.splice(index, 1);
  }

  buscarTipos() {
    this.util.showLoading();
    var url = API + 'TipoQrCodeEntrada';

    this.ajax.get(url)
      .subscribe(resp => {
        console.log(resp);
        this.arrayTipos = resp;
        this.tipoCampoSelecionado = "1696e7dc-514e-4c31-b713-8bb4e4442ea9"; // texto livre
        this.util.closeLoading();

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao buscar Dados");
      });
  }

  validarQrcode(){

    if (this.nomeQrcode == undefined || this.nomeQrcode == "") {
      this.util.toasterShowError("Atenção", "Entre com um Título para o QRCODE");
      return false;
    }

    if (this.redirecionarQrcode == undefined || this.redirecionarQrcode == "") {
      this.util.toasterShowError("Atenção", "Entre com a URL de redirecionamento que será acionada após ao usuário entrar com os dados solicitados");
      return false;
    }


    if (this.arrayFormulario == undefined || this.arrayFormulario.length == 0) {
      this.util.toasterShowError("Atenção", "Entre com pelo menos um campo");
      return false;
    }

    return true;
  }

  salvarTemplatePerguntas(idQr: string){

    let dados = {
      "dadosTemplateEntrada": this.arrayFormulario
    }

    console.log(dados);
    let url = API + "QrcodeEntradaTemplate/" + idQr;
    this.util.showLoading();
    return this.ajax.post(url, dados)
      .subscribe((resp) => {
        debugger
        this.util.closeLoading();

        this.util.toasterShowSuccess("Sucesso", "Cadastrado com sucesso!");
        this.router.navigate(['/home']);

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.messageError(err);
      });;

  }

  criarQrcode(){

    if(this.validarQrcode()){

      this.util.showLoading();
      this.salvarQRCode()
      .subscribe((resp) => {
        debugger
        this.util.closeLoading();
        this.salvarTemplatePerguntas(resp.id);

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.messageError(err);
      });

    }

  }

  salvarQRCode() {
    let dadosQrcode = {
      "idTipoQrCode": "39943721-E576-491A-80AB-59BCE4E3C435", // Entrada de Dados
      "nome": this.nomeQrcode,
      "ativo": true,
      "conteudo": this.descricaoQrcode,
      "idSubtipoQrCode": "1E5A0F06-1F5B-4FB8-A11D-9DF8B8FC515A", // tipo Comum
      "urlRedirectEntradaDados": this.redirecionarQrcode
    }

    let url = API + "User/" + this.user.user.id + "/QrCode";
    return this.ajax.post(url, dadosQrcode);
  }

}
