import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import { WizardComponent } from 'angular-archwizard';
import { DadosCartao } from 'src/app/model/CartaoVisita';
import { ResponseUser } from 'src/app/model/User.model';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API, maskTeleFixo, maskTelefoneCelular, SUBTIPOS_CARTAO_VISITA } from 'src/environments/environment';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cadastro-cartao-visita',
  templateUrl: './cadastro-cartao-visita.component.html',
  styleUrls: ['./cadastro-cartao-visita.component.scss']
})
export class CadastroCartaoVisitaComponent implements OnInit {

  @ViewChild(WizardComponent)
  public wizard: WizardComponent;
  public uploadedFilesCapa: File;
  public uploadedFilesAvatar: File;
  public uploadFoto: File;
  public uploadMusica: File;
  public nomeQrcode: string;
  public descricaoQrcode: string;
  public maskTeleArea = maskTelefoneCelular;
  public maskTeleAreaFixo = maskTeleFixo;
  public urlGeral: string;
 public isEdicao:boolean=false;
  /*public linkYoutube: DadosCartao = new DadosCartao();
  public linkFacebook: DadosCartao = new DadosCartao();
  public linkTwitter: DadosCartao = new DadosCartao();
  public linkTelefone: DadosCartao = new DadosCartao();
  public linkTelefoneFixo: DadosCartao = new DadosCartao();
  public linkLinkedin: DadosCartao = new DadosCartao();
  public linkInstagram: DadosCartao = new DadosCartao();
  public linkSite: DadosCartao = new DadosCartao();
  public linkWhatsapp: DadosCartao = new DadosCartao();
  public linkTelegram: DadosCartao = new DadosCartao();
  public linkMapa: DadosCartao = new DadosCartao();*/
  public mensagemWhatsapp: string;

  public linkFundo: DadosCartao = new DadosCartao();
  public linkFoto: DadosCartao = new DadosCartao();
  public user: ResponseUser;
  public contatos: any[];
  public arrayOrdem: number[];

  public rua: string;
  public numero: number;
  public bairro: string;
  public estado: string;
  public cidade: string;
  public cep: string;
  public selectedIdQrcode:string;
  arrayTipos: any;
  tipoCampoSelecionado: string;

  //contatos: DadosCartao[]
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private ajax: AjaxService,
    public util: UtilService,
    private auth: AuthService
  ) {

  }

  ngOnInit() {
    try{
       this.selectedIdQrcode = this.activatedRoute.snapshot.params['qr'];
       this.isEdicao =    this.selectedIdQrcode != null &&    this.selectedIdQrcode != '';
    }catch(e){
    }
    this.tipoCampoSelecionado = "1196e7dc-524e-4c31-b713-8bb4e4442ea0";
    this.contatos = [];
    this.buscarTipos();
    this.user = this.auth.getUser();
    this.mensagemWhatsapp = "Ola";

    if(this.selectedIdQrcode)
      this.irParaUltimaAba();
  }

  irParaUltimaAba(){

  setTimeout(() => {
    this.wizard.goToNextStep();
  }, 200);

  setTimeout(() => {
    this.wizard.goToNextStep();
  }, 400);
  }

  validaAdd() {

    if (this.tipoCampoSelecionado == "2896e8dc-515e-5c32-b714-7bb4e4542ea4") { // Mapa

      if (this.rua != undefined || this.numero != undefined || this.bairro != undefined || this.estado != undefined || this.cidade != undefined) {
        if (this.rua == undefined || this.rua == "") {
          this.util.toasterShowError("Atenção", "Entre com nome da Rua");
          return false;
        }
        if (this.numero == undefined) {
          this.util.toasterShowError("Atenção", "Entre com Número");
          return false;
        }
        if (this.bairro == undefined || this.bairro == "") {
          this.util.toasterShowError("Atenção", "Entre com o Bairro");
          return false;
        }
        if (this.cidade == undefined || this.cidade == "") {
          this.util.toasterShowError("Atenção", "Entre com a Cidade");
          return false;
        }
        if (this.estado == undefined || this.estado == "") {
          this.util.toasterShowError("Atenção", "Entre com o Estado");
          return false;
        }
      }
    } else if (this.urlGeral == undefined || this.urlGeral == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo Link");
      return false;
    }

    return true;
  }

  validaOrdem(item) {
    if (item.ordem == 0) {
      item.ordem = 1;
      this.util.toasterShowError("Atenção", "Ordem precisa ser maior q 'Zero'");
    }
  }

  addItem() {
    debugger
    if (this.validaAdd()) {

      let tipo = this.arrayTipos.find(c => c.id == this.tipoCampoSelecionado);

      let dados = new DadosCartao();
      dados.Chave = tipo.nome;
      dados.IdTipoQrCodeCartaoVisita = tipo.id;
      dados.Valor = this.util.montarUrl(dados.Chave.toLowerCase(), this.urlGeral, this.mensagemWhatsapp);
      dados.ordem = dados.Ordem = this.contatos.length + 1;

      if (tipo.id == "2896e8dc-515e-5c32-b714-7bb4e4542ea4") {// Mapa
        let end = this.rua + "," + this.numero + "," + this.bairro + "," + this.cidade + "," + this.estado;
        dados.Valor = this.util.montarUrl(dados.Chave.toLowerCase(), end);
      }

      if (tipo.id == "1596e7dc-514e-4c31-b713-8bb4e4442ea1") { // Foto
        dados.Chave = this.urlGeral;
        dados.Valor = this.urlGeral;
        dados.ValorArquivo = this.uploadFoto;
      }

      if (tipo.id == "135a0f06-1f5b-4fb7-a11d-8df7b8fc337a") { // musica
        dados.Chave = this.urlGeral;
        dados.Valor = this.urlGeral;
        dados.ValorArquivo = this.uploadMusica;
        dados.ordem =0;
      }

      this.contatos.push(dados);
      this.limparItem();
    }

  }

  limparItem() {
    this.urlGeral = "";
    this.mensagemWhatsapp = "";
    this.rua = "";
    this.numero = 0;
    this.bairro = "";
    this.cidade = "";
    this.estado = "";
    this.tipoCampoSelecionado = "";
    this.uploadFoto = undefined;
    this.uploadMusica = undefined;
  }


  validarQrcode() {

    if (this.uploadedFilesCapa == undefined) {
      this.util.toasterShowError("Atenção", "Escolha uma Foto para Capa");
      return false;
    }

    if (this.uploadedFilesAvatar == undefined) {
      this.util.toasterShowError("Atenção", "Escolha uma foto para Avatar");
      return false;
    }

    if (this.nomeQrcode == undefined || this.nomeQrcode == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo Nome");
      return false;
    }

    if (this.descricaoQrcode == undefined || this.descricaoQrcode == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo Descrição");
      return false;
    }

    debugger
    if (this.contatos.length <= 2) {
      this.util.toasterShowError("Atenção", "Escolha ao menos uma forma de contato");
      return false;
    }

    return true;
  }

  addCapaAvatar() {
    this.linkFundo.Chave = "fundo";
    this.linkFundo.Ordem = 0;
    this.linkFundo.Valor = this.uploadedFilesCapa[0].name;
    this.linkFundo.ValorArquivo = this.uploadedFilesCapa;
    this.linkFundo.IdTipoQrCodeCartaoVisita = "1596E7DC-514E-4C31-B713-8BB4E4442EA0";
    this.contatos.push(this.linkFundo);

    this.linkFoto.Chave = "foto";
    this.linkFoto.Ordem = 0;
    this.linkFoto.Valor = this.uploadedFilesAvatar[0].name;
    this.linkFoto.ValorArquivo = this.uploadedFilesAvatar;
    this.linkFoto.IdTipoQrCodeCartaoVisita = "1596E7DC-514E-4C31-B713-8BB4E4442EA1";
    this.contatos.push(this.linkFoto);
  }

  criarQrcode() {

    Swal.fire({
      title: 'Deseja Criar QRCODE?',
      text: 'Certifique-se que todos os itens foram adicionados a lista !',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true
    }).then((willDelete) => {
      if (willDelete.dismiss) {
        Swal.fire('Atenção', 'Operação Cancelada, pode conferir e salvar novamente!', 'warning');
      } else {

     if(this.isEdicao)
        this.editarQrCode();
     else
        this.cadastrarQrCode();
      }
    });
  }



  editarQrCode(){

      this.util.showLoading();
      this.salvarContatos(this.selectedIdQrcode);
  }

  cadastrarQrCode(){
    this.addCapaAvatar();
    if (this.validarQrcode()) {

      this.util.showLoading();
      this.salvarQRCode()
        .subscribe((resp) => {
          debugger
          this.util.closeLoading();
          this.salvarContatos(resp.id);

        }, err => {
          debugger
          this.util.closeLoading();
          this.util.messageError(err);
        });
    }
  }

  salvarContatos(idQrcode: string) {

    let dados = {
      "IdQrCode": idQrcode,
      "DadosCartao": this.contatos
    }
    debugger
    console.log(dados);
    let formData = this.util.jsonToFormData(dados);


    let url = API + "QrCodeCartaoVisita/" + idQrcode + "/File";
    this.util.showLoading();
    return this.ajax.postFile(url, formData)
      .subscribe((resp) => {
        debugger
        this.util.closeLoading();

        this.util.toasterShowSuccess("Sucesso", "Cadastrado com sucesso!");
        this.router.navigate(['/home']);

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.messageError(err);
      });

  }

  salvarQRCode() {
    let dadosQrcode = {
      "idTipoQrCode": "70B918A0-4B21-4DDD-88F3-9EF23408FB12", // Entrada de Dados
      "nome": this.nomeQrcode,
      "ativo": true,
      "conteudo": this.descricaoQrcode,
      "idSubtipoQrCode": "1E5A0F06-1F5B-4FB8-A11D-9DF8B8FC515A" // tipo Comum

    }

    let url = API + "User/" + this.user.user.id + "/QrCode";
    return this.ajax.post(url, dadosQrcode);
  }

  deleteItem(index) {
    this.contatos.splice(index, 1);
  }

  buscarTipos() {
    this.arrayTipos = SUBTIPOS_CARTAO_VISITA;
  }


  validarInformacoes() {
    if (this.nomeQrcode == undefined || this.nomeQrcode == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo Nome");
      return false;
    }

    if (this.descricaoQrcode == undefined || this.descricaoQrcode == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo Descrição");
      return false;
    }

    this.wizard.goToNextStep();
  }



  validarFotos() {

    if (this.uploadedFilesCapa == undefined) {
      this.util.toasterShowError("Atenção", "Escolha uma Foto para Capa");
      return false;
    }

    if (this.uploadedFilesAvatar == undefined) {
      this.util.toasterShowError("Atenção", "Escolha uma foto para Avatar");
      return false;
    }

    this.wizard.goToNextStep();
  }

}
