<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Aguarde ...</p>
  </ngx-spinner>
<div class="row">
  <div class="col-sm-12">
    <app-card cardTitle="Cadastro do Titulo do QRCode" [options]="false">
      <div class="row">
        <div class="col-sm-12 col-xl-4 m-b-30">
          <label class="sub-title">Título do QrCode</label>
          <input type="text" class="form-control" [(ngModel)]="nomeQrcode" name="nomeQrcode" placeholder="">
        </div>
        <div class="col-sm-12 col-xl-8 m-b-30">
          <label class="sub-title">Descricao</label>
          <input type="text" class="form-control" [(ngModel)]="descricaoQrcode" name="descricaoQrcode" placeholder="">
        </div>

        <div class="col-sm-12 col-xl-8 m-b-30">
          <label class="sub-title">Redirecionar Para</label>
          <input type="text" class="form-control" [(ngModel)]="redirecionarQrcode" name="redirecionarQrcode" placeholder="">
        </div>
      </div>

    </app-card>
  </div>
  <div class="col-sm-12">
    <!-- Default select start -->
    <app-card cardTitle="Cadastro dos campos do Formulário" [options]="false">
      <div class="row">
        <div class="col-sm-12 col-xl-3 m-b-30">
          <label class="sub-title">Escolha o tipo de campo</label>
          <select [(ngModel)]="tipoCampoSelecionado" name="tipoCampoSelecionado"
            class="form-control form-control-default">
            <option *ngFor="let item of arrayTipos" value={{item.id}}>
              {{item.descricao}}
          </select>
        </div>        

      </div>
      <div class="row">
        <div class="col-sm-12 col-xl-5 m-b-30">
          <label class="sub-title">Entre com a Descricao do Campo (Label)</label>
          <input type="text" class="form-control" [(ngModel)]="campoLabel" name="campoLabel" placeholder="" >
        </div>
        <div class="col-sm-12 col-xl-2 m-b-30">
            <label class="sub-title"> &nbsp; </label>
            <div class="custom-control custom-switch">
              <input type="checkbox" class="custom-control-input" id="customswitch1" [(ngModel)]="ehObrigatorio" >
              <label class="custom-control-label" for="customswitch1">É Obrigatório?</label>
            </div>
          </div>
        <div class="col-sm-12 col-xl-5" *ngIf="tipoCampoSelecionado == '1696e7dc-514e-4c31-b713-8bb4e4442ea2'">
          <label class="sub-title">Digite a opção e aperte a tecla <code>ENTER</code></label>
          <tag-input [ngClass]="'tag-select'" theme='bootstrap' [placeholder]="'+'" [(ngModel)]="arraySelectOpcao"
            [addOnBlur]="true" [clearOnBlur]="true">
            <tag-input-dropdown></tag-input-dropdown>
          </tag-input>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-xl-6 m-b-30">
          <button type="button" class="btn btn-success" (click)="addItem()"> Adicionar um Tipo &nbsp;<i
              class="fa fa-plus"></i>
          </button>
        </div>
      </div>
    </app-card>
  </div>
</div>
<div class="row">
  <div class="col-sm-12 col-xl-8">
    <app-card [hidHeader]="true" *ngFor="let x of arrayFormulario; let index = index;">
        <div class="app-card-header">
            <div class="card-header-right">
              <h5 class="text-muted">$25</h5>
            </div>
          </div>
      <div class="row">
        <div class="col-sm-5 col-xl-5">
          <label class="sub-title">Tipo de Campo</label>
          <h5 class="m-0">{{x.nomeCampo}}</h5>
        </div>
        <div class="col-sm-2 col-xl-2">
          <label class="sub-title">Ordem</label>
          <input type="number" class="form-control" [(ngModel)]="x.ordem" placeholder="">
        </div>
        <div class="col-sm-4 col-xl-4">
          <label class="sub-title" *ngIf="x.idTipoEntrada != '1696e7dc-514e-4c31-b713-8bb4e4442ea1'">{{x.chave}}</label>

          <div class="custom-control custom-checkbox" *ngIf="x.idTipoEntrada == '1696e7dc-514e-4c31-b713-8bb4e4442ea1'">  <!-- Check -->
            <br>
            &nbsp;&nbsp;<input type="checkbox" class="custom-control-input" id="customCheck1">
            <label class="custom-control-label" for="customCheck1">{{x.chave}}</label>
          </div>

          <input type="text" class="form-control" 
            *ngIf="x.idTipoEntrada == '1696e7dc-514e-4c31-b713-8bb4e4442ea7'" [textMask]="{mask: maskCPF}" > <!-- CPF -->
          <input type="text" class="form-control" 
            *ngIf="x.idTipoEntrada == '1696e7dc-514e-4c31-b713-8bb4e4442ea5'"> <!-- E-mail -->
          <input type="text" class="form-control" 
            *ngIf="x.idTipoEntrada == '1696e7dc-514e-4c31-b713-8bb4e4442ea3'" [textMask]="{mask: numberMaskInt}">
          <!-- Numérico (não aceita ponto ou vírgula) -->
          <input type="text" class="form-control" 
            *ngIf="x.idTipoEntrada == '1696e7dc-514e-4c31-b713-8bb4e4442ea6'"> <!-- RG -->

          <select class="form-control form-control-default"
            *ngIf="x.idTipoEntrada == '1696e7dc-514e-4c31-b713-8bb4e4442ea2'"> <!-- Seleção de Dados -->            
            <option *ngFor="let i of x.valores">{{i}}
          </select>

          <input type="text" class="form-control"
            *ngIf="x.idTipoEntrada == '1696e7dc-514e-4c31-b713-8bb4e4442ea8'" [textMask]="{mask: maskTelefoneCelular}" > <!-- Telefone -->
          <input type="text" class="form-control" 
            *ngIf="x.idTipoEntrada == '1696e7dc-514e-4c31-b713-8bb4e4442ea9'"> <!-- Texto Livre -->
          <input type="text" class="form-control" 
            *ngIf="x.idTipoEntrada == '1696e7dc-514e-4c31-b713-8bb4e4442ea4'" [textMask]="{mask: numberMaskReal}">      <!-- Valor Monetário (aceita ponto ou vírgula) -->

        </div>
        <div class="col-sm-1 col-xl-1">
            <label class="sub-title"> &nbsp;</label>
            <button type="button" (click)="deleteItem(index)" class="btn btn-icon btn-danger"
            ngbTooltip="Retirar da lista"><i class="feather icon-trash-2 "></i> </button>
        </div>
      </div>
    </app-card>
    <app-card [hidHeader]="true" align="center">
        <button type="button" class="btn btn-success btn-lg" (click)="criarQrcode()"> CRIAR QRCODE &nbsp;<i
            class="fa fa-qrcode"></i>
        </button>
      </app-card>
  </div>
  <div class="col-sm-12 col-xl-6">
    
  </div>
</div>
