import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-estatistica',
  templateUrl: './estatistica.component.html',
  styleUrls: ['./estatistica.component.scss']
})
export class EstatisticaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
