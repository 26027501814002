export class CartaoVisita {
  IdQrCode: string;
  dataCadastro: DadosCartao[];

}

export class DadosCartao {
  IdTipoQrCodeCartaoVisita: string;
  idTipoQrcodeCartaoVisita: string;
  Chave: string;
  Valor: string;
  Ordem: number;
  ValorArquivo: File;

  tipoQrcodeCartaoVisita: string;
  idTipoQrCodeCartaoVisita: string;
  chave: string;
  valor: string;
  ordem: number;
  valorArquivo: File
}

