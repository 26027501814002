<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>

<div class="row">
  <div class="col-sm-12">


    <app-card [hidHeader]="true" cardClass="user-profile-list">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <label for="exampleInputEmail1">Filtro</label>
          <input type="text" class="form-control" [(ngModel)]="query"
            placeholder="Digite seu filtro, sera feito uma busca em todas as colunas" (keyup)="onKeydownEvent($event)">
        </div>
        <!--<div class="col-md-2 col-sm-12">
          <br>
          <label for="exampleInputEmail1">&nbsp;</label>
          <button type="button"  class="btn btn-icon btn-warning"><i
            class="feather icon-refresh-cw"></i> Atualizar</button>
        </div>-->
      </div>
      <br>
      <div class="dt-responsive table-responsive">
        <table datatable class="table table-striped row-border table-hover">
          <!--<thead>
              <tr>
                <th>Name</th>
                <th>Position</th>
                <th>Office</th>
                <th>Age</th>
                <th>Start date</th>
                <th>Status</th>
              </tr>
            </thead>-->
          <tbody>
            <tr *ngFor="let qr of arrayQrcode  | fullTextSearch:query">
              <td>
                <div class="d-inline-block align-middle">
                  <!--<img src="assets/images/user/avatar-1.jpg" alt="user image" class="img-radius align-top m-r-15" style="width:40px;">-->
                  <!--<canvas id="ce7c161d-2506-4b9e-ac2b-88eb52814b0f" style="width:60px;"></canvas> -->
                  <!--<canvas id="canvas" style="width:60px;"></canvas> -->

                  <canvas id="{{qr.id}}" class=" align-top m-r-15"></canvas>


                  <div class="d-inline-block">
                    <span *ngIf="qr.subtipo == 'Comum' && qr.tipo == 'Cartão de Visita'" class="badge badge-light-info">
                      <i class="far fa-id-card text-info mr-2"></i> Cartão de Visita</span>
                      <span *ngIf="qr.subtipo == 'Comum' && qr.tipo == 'Entrada de Dados'" class="badge badge-light-success">
                        <i class="far fa-file-word text-success mr-2"></i> Formulario</span>

                    <span *ngIf="qr.subtipo == 'Comum' && (qr.tipo != 'Cartão de Visita' && qr.tipo != 'Entrada de Dados')" class="badge badge-light-info">
                      <i class="fas fa-globe text-info mr-2"></i> Link</span>

                    <span *ngIf="qr.subtipo == 'Mapa'" class="badge badge-light-primary"> <i
                        class="feather icon-map text-primary mr-2"></i> Mapa</span>

                    <span *ngIf="qr.subtipo == 'Whatsapp'" class="badge badge-light-success"> <i
                        class="fab fa-whatsapp text-success mr-2"></i> Whatsapp</span>
                    <span *ngIf="qr.subtipo == 'Telegram'" class="badge badge-light-info"> <i
                        class="fab fa-telegram text-info mr-2"></i> Telegram</span>
                    <span *ngIf="qr.subtipo == 'Facebook'" class="badge badge-light-primary"> <i
                        class="fab fa-facebook-f text-primary mr-2"></i> Facebook</span>
                    <span *ngIf="qr.subtipo == 'Twitter'" class="badge badge-light-primary"> <i
                        class="fab fa-twitter text-primary mr-2"></i> Twitter</span>
                    <span *ngIf="qr.subtipo == 'Youtube'" class="badge badge-light-danger"> <i
                        class="fab fa-youtube text-danger mr-2"></i> Youtube</span>
                    <span *ngIf="qr.subtipo == 'Linkedin'" class="badge badge-light-info"> <i
                        class="fab fa-linkedin-in text-info mr-2"></i> Linkedin</span>
                    <span *ngIf="qr.subtipo == 'Instagram'" class="badge badge-light-danger"> <i
                        class="fab fa-instagram text-danger mr-2"></i> Instagram</span>
                    <span *ngIf="qr.subtipo == 'Texto'" class="badge badge-light-warning"> <i
                        class="fas fa-pencil-alt text-warning mr-2"></i> Texto</span>
                    <span *ngIf="qr.subtipo == 'Conteudo'" class="badge badge-light-warning"> <i
                        class="far fa-file-alt text-warning mr-2"></i> Conteudo</span>
                    <span *ngIf="qr.subtipo == 'Telefone'" class="badge badge-light-info"> <i
                        class="feather icon-phone text-info mr-2"></i> Telefone</span>

                    <span *ngIf="qr.subtipo == 'Pdf'" class="badge badge-light-danger"> <i
                        class="far fa-file-pdf text-danger mr-2"></i> PDF</span>

                    <span *ngIf="qr.subtipo == 'Mp3'" class="badge badge-light-dark"> <i
                        class="fa fa-music text-dark mr-2"></i> MP3</span>

                    <span *ngIf="qr.subtipo == 'Arquivo'" class="badge badge-light-success"> <i
                        class="fa fa-archive text-success mr-2"></i> Arquivo</span>

                    <h4 class="m-b-0">{{qr.nome}}</h4>


                  </div>
                </div>
              </td>
              <td align="center">
                <strong class="text-muted">SCANS</strong>
                <br>
                <div class="text-muted">{{qr.quantidadeAcessos}}</div>

              </td>
              <td class="text-muted">{{qr.tipo}}</td>
              <td class="text-muted">{{qr.dataCadastro | date: 'dd/MM/yyyy'}}</td>
              <td>

                <span *ngIf="qr.ativo" class="badge badge-light-success">Ativo</span>
                <span *ngIf="!qr.ativo" class="badge badge-light-danger">Inativo</span>

              </td>
              <td>
                <button type="button" (click)="verEstatisticas(qr)" class="btn btn-icon btn-warning"
                  ngbTooltip="Ver Estatísticas"><i class="fas fa-eye "></i> </button> &nbsp;&nbsp;
                <button type="button" (click)="editar(qr)" class="btn btn-icon btn-info" ngbTooltip="Editar QrCode"><i
                    class="icon feather icon-edit"></i> </button>&nbsp;&nbsp;
                <button type="button" (click)="downloadQrcode(qr)" class="btn btn-icon btn-danger"
                  ngbTooltip="Download da Imagem"><i class="fas fa-download"></i> </button>&nbsp;&nbsp;
                <button type="button" (click)="modalConfiguracao(qr)" class="btn btn-icon btn-secondary "
                  ngbTooltip="Configurações"><i class="fa fa-cog"></i> </button>
              </td>
            </tr>

          </tbody>
          <tfoot>

          </tfoot>
        </table>
      </div>
    </app-card>
  </div>
</div>
