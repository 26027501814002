
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import { WizardComponent } from 'angular-archwizard';
import { DadosCartao } from 'src/app/model/CartaoVisita';
import { ResponseUser } from 'src/app/model/User.model';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API } from 'src/environments/environment';

@Component({
  selector: 'app-cadastrar-qrcode',
  templateUrl: './cadastrar-qrcode.component.html',
  styleUrls: ['./cadastrar-qrcode.component.scss']
})
export class CadastrarQrcodeComponent implements OnInit {

  @ViewChild(WizardComponent)
  public wizard: WizardComponent;
  public uploadedFilesCapa: File;
  public uploadedFilesAvatar: File;
  public nome: string;
  public descricao: string;
  public maskTeleArea = ['(', /\d/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public maskTeleAreaFixo = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public linkYoutube: DadosCartao = new DadosCartao();
  public linkFacebook: DadosCartao = new DadosCartao();
  public linkTwitter: DadosCartao = new DadosCartao();
  public linkTelefone: DadosCartao = new DadosCartao();
  public linkTelefoneFixo: DadosCartao = new DadosCartao();
  public linkLinkedin: DadosCartao = new DadosCartao();
  public linkInstagram: DadosCartao = new DadosCartao();
  public linkSite: DadosCartao = new DadosCartao();
  public linkWhatsapp: DadosCartao = new DadosCartao();
  public linkTelegram: DadosCartao = new DadosCartao();
  public linkMapa: DadosCartao = new DadosCartao();
  public mensagemWhatsapp: string;

  public linkFundo: DadosCartao = new DadosCartao();
  public linkFoto: DadosCartao = new DadosCartao();
  public user: ResponseUser;
  public contatos: any[];
  public arrayOrdem: number[];

  public rua: string;
  public numero: number;
  public bairro: string;
  public estado: string;
  public cidade: string;
  public cep: string;


  constructor(
    private router: Router,
    private ajax: AjaxService,
    public util: UtilService,
    private auth: AuthService
  ) {
    this.contatos = [];
    this.arrayOrdem = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
    this.linkFundo.IdTipoQrCodeCartaoVisita = "1596E7DC-514E-4C31-B713-8BB4E4442EA0";
    this.linkFoto.IdTipoQrCodeCartaoVisita = "1596E7DC-514E-4C31-B713-8BB4E4442EA1";

    this.linkTelefone.IdTipoQrCodeCartaoVisita = "1896E7DC-514E-5C31-B713-7BB4E4442EA4";
    this.linkTelefone.Ordem = 1;

    this.linkTelefoneFixo.IdTipoQrCodeCartaoVisita = "1596E7DC-514E-4C31-B713-8BB4E4442EA2";
    this.linkTelefoneFixo.Ordem = 2;

    this.linkWhatsapp.IdTipoQrCodeCartaoVisita = "1196E7DC-524E-4C31-B713-8BB4E4442EA0";
    this.linkWhatsapp.Ordem = 3;

    this.linkSite.IdTipoQrCodeCartaoVisita = "1096E7DC-534E-4C31-B713-8BB4E4442EA5";
    this.linkSite.Ordem = 4;

    this.linkFacebook.IdTipoQrCodeCartaoVisita = "1596E7DC-514E-4C31-B713-8BB4E4442EA8";
    this.linkFacebook.Ordem = 5;

    this.linkTwitter.IdTipoQrCodeCartaoVisita = "1596E7DC-514E-4C31-B713-8BB4E4442EA3";
    this.linkTwitter.Ordem = 6;

    this.linkYoutube.IdTipoQrCodeCartaoVisita = "1596E7DC-514E-4C31-B713-8BB4E4442EA4";
    this.linkYoutube.Ordem = 7;

    this.linkLinkedin.IdTipoQrCodeCartaoVisita = "1596E7DC-514E-4C31-B713-8BB4E4442EA6";
    this.linkLinkedin.Ordem = 8;

    this.linkInstagram.IdTipoQrCodeCartaoVisita = "1596E7DC-514E-4C31-B713-8BB4E4442EA7";
    this.linkInstagram.Ordem = 9;

    this.linkTelegram.IdTipoQrCodeCartaoVisita = "1286E7DC-524E-4C31-B713-8BB4E4442EA3";
    this.linkTelegram.Ordem = 10;

    this.linkMapa.IdTipoQrCodeCartaoVisita = "2896E8DC-515E-5C32-B714-7BB4E4542EA4";
    this.linkMapa.Ordem = 11;


  }

  ngOnInit() {
    this.user = this.auth.getUser();
    this.mensagemWhatsapp = "Ola";
  }

  uploadFile() {
    console.log(this.uploadedFilesCapa, this.uploadedFilesAvatar);
  }




  salvarContatos(idQrcode) {

    let dados = {
      "IdQrCode": idQrcode,
      "DadosCartao": this.contatos
    }
    debugger
    console.log(dados);
    let formData = this.util.jsonToFormData(dados);


    let url = API + "QrCodeCartaoVisita/" + idQrcode + "/File";
    this.util.showLoading();
    return this.ajax.postFile(url, formData)
      .subscribe((resp) => {
        debugger
        this.util.closeLoading();

        this.util.toasterShowSuccess("Sucesso", "Cadastrado com sucesso!");
        this.router.navigate(['/home']);

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.messageError(err);
      });
  }


  montarArrayContato() {
    this.contatos = [];

    debugger

    this.linkFundo.Chave = "fundo";
    this.linkFundo.Ordem = 0;
    this.linkFundo.Valor = this.uploadedFilesCapa[0].name;
    this.linkFundo.ValorArquivo = this.uploadedFilesCapa;
    this.contatos.push(this.linkFundo);

    this.linkFoto.Chave = "foto";
    this.linkFoto.Ordem = 0;
    this.linkFoto.Valor = this.uploadedFilesAvatar[0].name;
    this.linkFoto.ValorArquivo = this.uploadedFilesAvatar;
    this.contatos.push(this.linkFoto);

    debugger
    if (this.linkTelefone.Valor != undefined && this.linkTelefone.Valor != "") {
      this.linkTelefone.Chave = "celular";
      this.linkTelefone.Valor =  this.util.montarUrl("telefone", this.linkTelefone.Valor);
      this.contatos.push(this.linkTelefone);
    }

    if (this.linkTelefoneFixo.Valor != undefined && this.linkTelefoneFixo.Valor != "") {
      this.linkTelefoneFixo.Chave = "telefone fixo";
      this.linkTelefoneFixo.Valor = this.util.montarUrl("telefone", this.linkTelefoneFixo.Valor);
      this.contatos.push(this.linkTelefoneFixo);
    }

    if (this.linkSite.Valor != undefined && this.linkSite.Valor != "") {
      this.linkSite.Chave = "site";
      this.linkSite.Valor = this.util.validateLink(this.linkSite.Valor);
      this.contatos.push(this.linkSite);
    }

    if (this.linkFacebook.Valor != undefined && this.linkFacebook.Valor != "") {
      this.linkFacebook.Chave = "facebook";
      this.linkFacebook.Valor = this.util.montarUrl("facebook", this.linkFacebook.Valor);
      this.contatos.push(this.linkFacebook);
    }

    if (this.linkTwitter.Valor != undefined && this.linkTwitter.Valor != "") {
      this.linkTwitter.Chave = "twitter";
      this.linkTwitter.Valor = this.util.montarUrl("twitter", this.linkTwitter.Valor);
      this.contatos.push(this.linkTwitter);
    }

    if (this.linkYoutube.Valor != undefined && this.linkYoutube.Valor != "") {
      this.linkYoutube.Chave = "youtube";
      this.linkYoutube.Valor = this.util.montarUrl("youtube", this.linkYoutube.Valor);
      this.contatos.push(this.linkYoutube);
    }

    if (this.linkLinkedin.Valor != undefined && this.linkLinkedin.Valor != "") {
      this.linkLinkedin.Chave = "linkedin";
      this.linkLinkedin.Valor = this.util.montarUrl("linkedin", this.linkLinkedin.Valor);
      this.contatos.push(this.linkLinkedin);
    }

    if (this.linkInstagram.Valor != undefined && this.linkInstagram.Valor != "") {
      this.linkInstagram.Chave = "instagram";
      this.linkInstagram.Valor = this.util.montarUrl("instagram", this.linkInstagram.Valor);
      this.contatos.push(this.linkInstagram);
    }

    if (this.linkWhatsapp.Valor != undefined && this.linkWhatsapp.Valor != "") {
      this.linkWhatsapp.Chave = "whatsapp";
      this.linkWhatsapp.Valor = this.util.montarUrl('whatsapp', this.linkWhatsapp.Valor, this.mensagemWhatsapp)
      this.contatos.push(this.linkWhatsapp);
    }

    if (this.linkTelegram.Valor != undefined && this.linkTelegram.Valor != "") {
      this.linkTelegram.Chave = "telegram";
      this.linkTelegram.Valor = this.util.montarUrl("telegram", this.linkTelegram.Valor);
      this.contatos.push(this.linkTelegram);
    }

    debugger
    if (this.rua != undefined && this.rua != "") {
      this.linkMapa.Chave = "mapa";
      this.linkMapa.Valor = this.rua + "," + this.numero + "," + this.bairro + "," + this.cidade + "," + this.estado;
      this.linkMapa.Valor = this.util.montarUrl("mapa", this.linkMapa.Valor);
      this.contatos.push(this.linkMapa);
    }

  }

  salvarCartao() {

    this.montarArrayContato();
    if (this.validarCartao()) {

      this.util.showLoading();
      this.salvarQRCode()
        .subscribe((resp) => {
          debugger
          this.util.closeLoading();
          this.salvarContatos(resp.id);

        }, err => {
          debugger
          this.util.closeLoading();
          this.util.messageError(err);
        });

    }
  }

  validarFotos() {

    if (this.uploadedFilesCapa == undefined) {
      this.util.toasterShowError("Atenção", "Escolha uma Foto para Capa");
      return false;
    }

    if (this.uploadedFilesAvatar == undefined) {
      this.util.toasterShowError("Atenção", "Escolha uma foto para Avatar");
      return false;
    }

    this.wizard.goToNextStep();
  }

  validarInformacoes() {
    if (this.nome == undefined || this.nome == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo Nome");
      return false;
    }

    if (this.descricao == undefined || this.descricao == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo Descrição");
      return false;
    }

    this.wizard.goToNextStep();
  }



  validarCartao() {

    if (this.uploadedFilesCapa == undefined) {
      this.util.toasterShowError("Atenção", "Escolha uma Foto para Capa");
      return false;
    }

    if (this.uploadedFilesAvatar == undefined) {
      this.util.toasterShowError("Atenção", "Escolha uma foto para Avatar");
      return false;
    }

    if (this.nome == undefined || this.nome == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo Nome");
      return false;
    }

    if (this.descricao == undefined || this.descricao == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo Descrição");
      return false;
    }

    debugger
    if (this.contatos.length <= 2) {
      this.util.toasterShowError("Atenção", "Escolha ao menos uma forma de contato");
      return false;
    }



    /*if (this.rua != undefined || this.numero != undefined || this.bairro != undefined || this.estado != undefined || this.cidade != undefined) {
      if (this.rua == undefined || this.rua == "") {
        this.util.toasterShowError("Atenção", "Entre com nome da Rua");
        return false;
      }
      if (this.numero == undefined ) {
        this.util.toasterShowError("Atenção", "Entre com Número");
        return false;
      }
      if (this.bairro == undefined || this.bairro == "") {
        this.util.toasterShowError("Atenção", "Entre com o Bairro");
        return false;
      }
      if (this.cidade == undefined || this.cidade == "") {
        this.util.toasterShowError("Atenção", "Entre com a Cidade");
        return false;
      }
      if (this.estado == undefined || this.estado == "") {
        this.util.toasterShowError("Atenção", "Entre com o Estado");
        return false;
      }
    }*/

    return true;
  }

  salvarQRCode() {
    let dadosQrcode = {
      "idTipoQrCode": "70B918A0-4B21-4DDD-88F3-9EF23408FB12", // cartao visita
      "nome": this.nome,
      "ativo": true,
      "conteudo": this.descricao,
      "idSubtipoQrCode": "1E5A0F06-1F5B-4FB8-A11D-9DF8B8FC515A" // tipo Comum

    }
    let url = API + "User/" + this.user.user.id + "/QrCode";

    return this.ajax.post(url, dadosQrcode);
  }


}
