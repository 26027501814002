import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-visualizar-cartao',
  templateUrl: './visualizar-cartao.component.html',
  styleUrls: ['./visualizar-cartao.component.scss']
})
export class VisualizarCartaoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
