
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './theme/shared/shared.module';

import { AppComponent } from './app.component';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { NavigationComponent } from './theme/layout/admin/navigation/navigation.component';
import { NavContentComponent } from './theme/layout/admin/navigation/nav-content/nav-content.component';
import { NavGroupComponent } from './theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import { NavCollapseComponent } from './theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavItemComponent } from './theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import { NavBarComponent } from './theme/layout/admin/nav-bar/nav-bar.component';
import { NavLeftComponent } from './theme/layout/admin/nav-bar/nav-left/nav-left.component';
import { NavSearchComponent } from './theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import { NavRightComponent } from './theme/layout/admin/nav-bar/nav-right/nav-right.component';
import {ChatUserListComponent} from './theme/layout/admin/nav-bar/nav-right/chat-user-list/chat-user-list.component';
import {FriendComponent} from './theme/layout/admin/nav-bar/nav-right/chat-user-list/friend/friend.component';
import {ChatMsgComponent} from './theme/layout/admin/nav-bar/nav-right/chat-msg/chat-msg.component';
import { ConfigurationComponent } from './theme/layout/admin/configuration/configuration.component';

import { ToggleFullScreenDirective } from './theme/shared/full-screen/toggle-full-screen';
import {ArchwizardModule} from 'angular-archwizard';

/* Menu Items */
import { NavigationItem } from './theme/layout/admin/navigation/navigation';
import { NgbAccordionModule, NgbButtonsModule, NgbCollapseModule, NgbDropdownModule, NgbTabsetModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CadastrarQrcodeComponent } from './page/cadastrar-qrcode/cadastrar-qrcode.component';
import { VisualizarCartaoComponent } from './page/visualizar-cartao/visualizar-cartao.component';
import { LoginComponent } from './page/login/login.component';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { EscolhaTipoQrcodeComponent } from './page/escolha-tipo-qrcode/escolha-tipo-qrcode.component';
import { ToastrModule } from 'ngx-toastr';
import {TextMaskModule} from 'angular2-text-mask';
import {NgNumberFormatterModule} from 'ng-number-formatter';

import { FullTextSearchPipe  } from './shared/filter.pipe';
import { LoggedInGuard } from './page/security/loggedin.quard';
import { NgxSpinnerModule } from 'ngx-spinner';
import {ColorPickerModule} from 'ngx-color-picker';
import { HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './page/home/home.component';
import { GComponent } from './page/g/g.component';
import {TinymceModule} from 'angular2-tinymce';
import { EditComponent } from './page/edit/edit.component';
import { CadastroUsuarioComponent } from './page/cadastro-usuario/cadastro-usuario.component';
import { ConfigComponent } from './page/config/config.component';
import { EstatisticaComponent } from './page/estatistica/estatistica.component';
import { CadastroTipoEntradaComponent } from './page/cadastro-tipo-entrada/cadastro-tipo-entrada.component';
import { SelectModule } from 'ng-select';
import {TagInputModule} from 'ngx-chips';
import { CadastroCartaoVisitaComponent } from './page/cadastro-cartao-visita/cadastro-cartao-visita.component';
import { recaptcha } from 'src/environments/environment';


@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    NavigationComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
    ChatUserListComponent,
    FriendComponent,
    ChatMsgComponent,
    ConfigurationComponent,
    ToggleFullScreenDirective,
    CadastrarQrcodeComponent,
    VisualizarCartaoComponent,
    LoginComponent,
    EscolhaTipoQrcodeComponent,
    HomeComponent,
    FullTextSearchPipe,
    GComponent,
    EditComponent,
    CadastroUsuarioComponent,
    ConfigComponent,
    EstatisticaComponent,
    CadastroTipoEntradaComponent,
    CadastroCartaoVisitaComponent
    
    

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule ,
    SharedModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbButtonsModule,
    NgbTabsetModule,
    FileUploadModule,
    NgxSpinnerModule,
    NgbCollapseModule,
    NgbAccordionModule,
    ArchwizardModule,
    TextMaskModule,
    ColorPickerModule,
    TinymceModule,
    SelectModule,
    TagInputModule,
    NgNumberFormatterModule,
    ToastrModule.forRoot(), // ToastrModule added
    
    
    
  ],
  providers: [NavigationItem, LoggedInGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
