import { ResponseUser } from './../model/User.model';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { User } from '../model/User.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: ResponseUser;
  lastUrl: string;
  storageName: string;

  constructor(private router: Router) {
    this.storageName = "const_config_qrcode";
  }


  isLoggedIn(): boolean{
    
    this.getUser();
    return this.user !== null;
  }


  clearUser() {
    localStorage.removeItem(this.storageName);
  }

  setUser(user: ResponseUser) {
    localStorage.setItem(this.storageName, JSON.stringify(user));
  }

  getUser(): ResponseUser {
    this.user = JSON.parse(localStorage.getItem(this.storageName));
    return this.user;
  }

  handleLogin(path: string = this.lastUrl) {
    this.router.navigate(['\login'])
  }

  logout() {
    this.clearUser();
    this.user = undefined;
    this.router.navigate(['\login'])

  }

  /*loginJson(cb) {
    const req = new XMLHttpRequest();
    req.open('GET', 'assets/data/login.json');

    req.onload = () => {
      cb(JSON.parse(req.response));
    };

    return req.send();
  }*/
}
